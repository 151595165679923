import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'

// plugins
import './plugins/registerServiceWorker'
import './plugins/bootstrap'
import './plugins/axios'
import './plugins/bodyScrollBar'
import './plugins/translator'
import './plugins/metrics'
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

// styles
import 'vue2-editor/dist/vue2-editor.css'
import './styles.scss'
import './post.css'
import './fonts/fonts.css'


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
