<template>
    <header>
        <nav>
            <div class="container">
                <div v-b-toggle.sidebar-right class="burger h2 p-2 mb-0">
                    <b-icon-list />
                </div>
                <router-link to="/"><img class="it-park" src="../assets/techvibe.svg" alt=""></router-link>
                <form :submit="search" class="search" autocomplete="off">
                    <input autocomplete="off" type="text" name="search" id="search" :placeholder="t(`search`)" @keyup.enter="search" v-model="searchText">
                    <button @click.stop.prevent="search">
                        <img src="../assets/search.png" alt="">
                    </button>
                </form>
                <div class="search-btn">
                    <button @click.prevent="$refs['search'].show()">
                        <img src="../assets/search.png" alt="">
                    </button>
                </div>
                <div class="btns-row">
                    <b-dropdown right class="mr-2 lang-choose">
                        <template #button-content class="shadow-none">
                            <span>{{ currLang }}</span>
                        </template>
                        <b-dropdown-item v-for="lang in Object.keys(langList)" :key="lang" @click="changeLocale(lang)">
                            <img :src="langIcons[lang]" :alt="lang + 'icon'" class="lang-icon mr-2">
                            <span class="font-weight-bold">{{ lang }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <router-link v-if="!userInfo.name" to="/login"><button class="main-outlined login">{{ t('login') }}</button></router-link>
                    <router-link v-if="!userInfo.name" to="/registrate"><button class="main registrate">{{ t('registrate') }}</button></router-link>
                    <router-link v-if="!userInfo.name" to="/registrate" class="ml-3 acc-ico"><img src="@/assets/profile-default.png" alt=""></router-link>
                    <b-dropdown v-else size="lg" right variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content class="shadow-none">
                            <div class="profile-pic" :style="userInfo.avatar ? {'background-image': `url(${userInfo.avatar})`} : {}">
                                <div v-if="userInfo.notifications_count" class="notification-dot" />
                            </div>
                        </template>
                        <b-dropdown-item href="/" @click.prevent="$router.push('/')" class="profile-dropdown">
                            <b-icon icon="house-door" class="mr-2" /> {{ t('mainPage') }}
                        </b-dropdown-item>
                        <b-dropdown-item href="/personal" @click.prevent="$router.push('/personal')" class="profile-dropdown">
                            <b-icon icon="person" class="mr-2" /> {{ t('personalPage') }}
                        </b-dropdown-item>
                        <b-dropdown-item href="/notifications" @click.prevent="$router.push('/notifications')" class="profile-dropdown">
                            <div v-if="userInfo.notifications_count" class="notification-dot mt-n2 ml-n1" />
                            <b-icon icon="bell" class="mr-2" /> {{ t('notifications') }}
                        </b-dropdown-item>
                        <b-dropdown-divider class="mr-2" />
                        <b-dropdown-item href="/news/create" @click.prevent="$router.push('/news/create')" class="profile-dropdown">
                            <b-icon icon="pencil-square" class="mr-2" /> {{ t('newPost') }}
                        </b-dropdown-item>
                        <b-dropdown-divider class="mr-2" />
                        <b-dropdown-item href="/logout" @click.prevent="$router.push('/logout')" class="profile-dropdown">
                            <b-icon-x-circle class="mr-2" /> {{ t('logout') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <b-modal
                ref="search" 
                hide-footer 
                :title="t(`search`)" 
            >
                <div class="modal-search">
                    <input autocomplete="false" name="search" @keyup.enter="search" v-model="searchText" type="text" :placeholder="t(`search`)">
                    <button @click.prevent="search">
                        <img src="../assets/search.png" alt="">
                    </button>
                </div>
            </b-modal>
        </nav>
        <b-sidebar id="sidebar-right" left shadow>
            <left-sidebar class="hiden-sidebar" showLogout="true" />
        </b-sidebar>
        <div class="container" role="navigation">
            <div class="blog-nav mx-auto">
                <div>
                    <router-link to="/" :class="[$route.path == '/' ? 'active-tab' : '']">{{ t('blogs') }}</router-link>
                    <div v-if="$route.path == '/'" class="underline"></div>
                </div>
                <span class="text-muted">|</span>
                <div>
                    <router-link to="/it-companies" :class="[$route.path == '/it-companies' ? 'active-tab' : '']">{{ t('itCompanies') }}</router-link>
                    <div v-if="$route.path == '/it-companies'" class="underline"></div>
                </div>
                <span class="text-muted">|</span>
                <div>
                    <router-link to="/events" :class="[$route.path == '/events' ? 'active-tab' : '']">{{ t('events') }}</router-link>
                    <div v-if="$route.path == '/events'" class="underline"></div>
                </div>
            </div>
        </div>  
    </header>
</template>

<script>
import LeftSidebar from './LeftSidebar.vue'
import en from '@/assets/uk.png'
import uz from '@/assets/uzbekistan.png'
import ru from '@/assets/russia.png'


export default {
    components: { LeftSidebar },
    data() {
        return {
            searchText: "",
            langIcons: {en , uz, ru}
        }
    },
    methods: {
        search() {
            event?.stopPropagation();
            event.preventDefault();
            if(this.searchText)
                this.$router.push('/?searchText=' + this.searchText)
            else
                this.$router.push('/')
        }
    },
    computed: {
        userInfo(){ 
            if(this) {
                this.$forceUpdate()
                return this.$store.state.userInfo
            } else {
                return {}
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    header {
        width: 100%;
    }
    input:focus {
        box-shadow: none !important;
        border-color: #DBDBDB !important;
    }
    .lang-icon {
        width: 20px;
    }
    nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 666;

        .container {
            height: 88px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .it-park {
            margin-right: 10px;
        }
        .main-outlined, .main {
            border-radius: 5px;
        }
        .search-btn {
            display: none;
            width: 100%;
            justify-content: flex-end;
            button {
                padding: 7px 15px;
                height: 100%;
                background: transparent;
                border: none;
                border-radius: 20px;
            }
        }
        .btns-row {
            margin-left: auto;
            display: flex;
            align-items: center;
            .main-outlined {
                margin-right: 10px;
            }
            .acc-ico {
                display: none;
            }
            .registrate {
                width: 180px;
            }
            .login {
                border-radius: 20px;
            }
            .registrate {
                border-radius: 20px;
            }
            .profile-pic {
                transition: all 0.4s;
                background-image: url('../assets/user.svg');
                min-width: 50px;
                max-width: 50px;
                border-radius: 25px;
                height: 50px;
                background-size: cover;
                background-position: center;
                box-shadow: 0 0 1px 3px transparent;

                &:hover {
                    box-shadow: 0 0 1px 3px var(--itpark);
                }

            }
            .notification-dot {
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 10px;
                background-color: var(--itpark);
            }
            button[aria-expanded="true"] > .profile-pic {
                box-shadow: 0 0 1px 3px var(--itpark) !important;
            }
            .dropdown {
                a {
                    font-weight: bold;
                    text-decoration: none;
                }
            }
        }
    }
    .b-sidebar-outer {
        z-index: 665 !important;
    }
    .hiden-sidebar {
        display: block !important;
    }
    .blog-nav {
        margin-top: 110px;
        background-color: white;
        border-radius: 10px;
        width: 100%;
        max-width: 790px;
        height: 51px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
        backdrop-filter: blur(32px);

        div {
            width: 33%;
            text-align: center;

            a {
                font-weight: bold;
                text-underline-offset: 10px;
                color: black
            }
            .active-tab {
                text-decoration: none !important;
            }

            .underline{
                height: 6px;
                width: 100%;
                max-width: 100px;
                margin: 0 auto;
                background-color: var(--itpark);
                margin-bottom: -7px;
                border-radius: 20px 20px 0 0;
            }
        }
    }
    .search {
        margin-left: auto;
    }
    .search, .modal-search {
        display: flex;
        height: 40px;
        width: 500px;
        input {
            height: 100%;
            width: 100%;
            background: transparent;
            border-radius: 20px 0 0 20px;
            font-weight: bold;
            padding-left: 20px;
            color: black;
            border: 2px solid #DBDBDB;
            border-right: none;

            ::placeholder {
                font-weight: normal !important;
                color: rgba(128, 128, 128, 0.404);
            }
        }
        button {
            padding: 0 15px;
            height: 100%;
            background: transparent;
            border: 2px solid #DBDBDB;
            border-left: none;
            border-radius: 0 20px 20px 0;
        }
    }
    
    .modal-search {
        max-width: 100%;

        input {
            font-size: 18px;
            color: black;
            font-weight: bold;
        }

        button {
            border-color: var(--itpark);
            background-color: var(--itpark);
        }
    }
    @media(min-width: 1400px) {
        .container {
            width: 1400px;
            max-width: 1400px;
        }
    }
    @media (min-width: 1200px) {
        .burger {
            outline: none !important;
            color: black;
            display: none;
        }
    }
    @media (max-width: 1200px) {
        .search {
            display: none !important;
        }
        .search-btn {
            display: flex !important;
        }
    }
    @media (max-width:790px) {
        .blog-nav {
            margin-top: 90px;
            font-size: 14px;
            border-radius: 0 !important;
        }
        .search {
            width: 150px !important;
        }
        header > .container {
            width: 100% !important;
            max-width: 100%;
            margin: 0;
            padding: 0;
            .blog-nav {
                width: 100%;
            }
        }
    }
    @media (max-width: 540px) {
        nav > .container {
            height: 60px;
        }
        .blog-nav {
            margin-top: 62px;
        }
    }
    @media (max-width: 992px) {
        .acc-ico {
            display: block !important;
        }
        .it-park {
            height: 35px !important;
        }
        .profile-pic {
            height: 35px !important;
            width: 35px !important;
        }
        .search {
            width: 300px !important;
        }
        .login {
            display: none !important;
        }
        .registrate {
            display: none !important;
        }
        .it-park {
            width: 100px;
            height: 16.23px !important;
        }
        .dropdown {
            .profile-pic {
                min-width: 35px !important;
            }
        }
    }
</style>