<template>
  <div id="app">
    <Navbar />
    <CoolLightBox class="view-image"
      :items="[imageUrl]" 
      :index="imageUrl ? 0 : null"
      @close="$store.commit('closeImage')" 
    />
    <b-modal size="lg" ref="my-modal" hide-footer @hide="hidePost" :title="postInfo.title">
      <OpenedPost v-if="postInfo.id != undefined" :postInfo="postInfo" />
      <div v-else class="d-flex justify-content-center"><b-spinner class="m-4" variant="success"></b-spinner></div>
      <b-button variant="light" pill @click.prevent="hidePost(); postModal.hide()" class="big-close">
        <b-icon-x-circle/>
      </b-button>
    </b-modal>
    <share />
    <Message />
    <router-view class="view"/>
    <div id="loadScreen" class="load-screen w-100 d-flex justify-content-center">
      <b-spinner variant="success"></b-spinner>
    </div>
    <button class="up-btn" id="upBtn" @click="up()"><b-icon-arrow-up-short /></button>
  </div>
</template>

<script>
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Navbar from './components/Navbar.vue'
import OpenedPost from './components/OpenedPost'
import CoolLightBox from 'vue-cool-lightbox'
import Message from './components/Message.vue'
import Share from './components/Share.vue'
import Vue from 'vue'

export default {
  name: 'App',
  data() {
    return {
      lastUrl: '',
      lastTitle: '',
      postInfo: {},
    }
  },
  computed: {
    imageUrl() {
      if(this) {
        this.$forceUpdate();
        return this.$store.state.imageUrl
      } else {
        return ''
      }
    },
  },
  methods: {
    up() {
      window.scroll(0, 0)
    },
    hidePost() {
      event?.preventDefault()
      this.changeurl(this.lastUrl)
      document.title = this.lastTitle
      this.postInfo = {}
      this.enableScrollBar()
    },
    async openPost(id, scroll2Comments=false, draft=false) {
      // this.disableScrollBar()
      event ? event.stopPropagation() : 0
      this.lastUrl = this.$route.fullPath
      this.lastTitle = document.title
      // this.postModal.show()
      // const dataResp = await this.$store.dispatch('getNewById', id)
      // this.changeurl('news/' + id, dataResp.title)
      // this.postInfo = dataResp;
      this.$router.push('news/' + id + (draft ? '?draft=true' : ''))
      if(scroll2Comments) {
        setTimeout(() => {
          document.getElementById("opened-post-comments").scrollIntoView();
        }, 200)
      }
    },
    changeurl(url="/", title="") {
      var new_url = url[0] == '/' ? url : '/' + url;
      window.history.pushState('data', title, new_url);
    }
  },
  components: {
    Navbar,
    CoolLightBox,
    Message,
    Share,
    OpenedPost,
  },
  async mounted() {
    Vue.prototype.postModal = this.$refs['my-modal']
    Vue.prototype.openPost = this.openPost
    Vue.prototype.changeurl = this.changeurl

    await setTimeout(async () => {
      if(!this.$store.state.currentlyLogging) {
        await this.$store.dispatch('loginWithCookies')
      }
      const loadScreen = document.getElementById('loadScreen');
      loadScreen?.classList?.add('d-none')

    }, 1000)

    const upBtn = document.getElementById('upBtn')
    const scrollBehavior = () => {
      const scroll = window.scrollY
      if(scroll > 1000) {
        upBtn.classList.add('visible')
      } else {
        upBtn.classList.remove('visible')
      }
    }
    scrollBehavior()
    window.addEventListener('scroll', scrollBehavior)
  }
}
</script>


<style lang="scss">
.view-image {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
.big-close {
  padding: 12px 15px !important;
  font-size: 20px !important;
  background-color: white;
  position: fixed;
  right: 10%;
  bottom: 10%;
}
body {
  max-width: 100vw;
  min-height: 60vh;
  background-color: #EFEFEF !important;
}
#app {
  min-height: 80%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .load-screen {
    transition: all 0.5s;
    width: 100vw;
    height: 100vh;

    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@media (max-width: 767px) {
  .big-close {
    bottom: 2% !important;
    right: 2% !important;
  }
}
</style>
