// your main.js
import Vue from 'vue'
import router from '../router/index'
import VueYandexMetrika from 'vue-yandex-metrika'                               
import VueGtag from "vue-gtag";
// import VueAnalytics from 'vue-analytics';

// Yandex metrika service
Vue.use(VueYandexMetrika, {
    id: 86557643,
    router: router,
    env: process.env.NODE_ENV
    // other options
})

Vue.use(VueGtag, {
    config: { id: "G-XXRMVBLR2J" }
}, router);

// GOOGLE ANALYTICS V < 4.0
// Google analytics service
// Vue.use(VueAnalytics, {
//     id: 'G-XXRMVBLR2J',
//     router
// })
