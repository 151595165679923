// vue cookies is important for performance
import cookies from 'vue-cookies'
import Vue from 'vue'
// importing locales
import uz from '../locales/uz'
import ru from '../locales/ru'
import en from '../locales/en'
//


export const langsList = {
    uz,
    ru,
    en
}
export let lang = cookies.get('locale') || 'uz'
document.getElementsByTagName('body')[0].classList.add('__' + lang)

class Translator {

    translate(name) {
        const list = langsList[lang]
        if(!list) {
            throw new Error('[Translator Error]: languages list is defined wrong or trying to access invalid language')
        }
        const translatedWord = list[name]
        if(!translatedWord) {
            console.error('[Translator Error]: accessing nonexisting word: ' + name)
            return ''
        } else {
            return translatedWord
        }
    }
    changeLocale(locale) {
        if(locale == lang) {
            console.log('[Translator]: Locale is the same')
        } else if(!langsList[locale]) {
            throw new Error('[Translator Error]: Such locale does not exist')
        } else {
            cookies.set('locale', locale, 365 * 24 * 60 * 3600)
            this.$root.$forceUpdate()
            location.reload()
            lang = locale
        }
    }

}
const translator = new Translator()

Vue.prototype.currLang = lang
Vue.prototype.t = translator.translate
Vue.prototype.langList = langsList
Vue.prototype.changeLocale = translator.changeLocale

export default {
    langsList,
    t: translator.translate,
    lang,
}