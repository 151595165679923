import axios from 'axios'

async function loginWithEmail(email, password) {
    const resp = await axios.post('auth/login', { email, password })
    const data = resp.data.data
    const token = data.token_type + ' ' + data.access_token
    data.token = token
    axios.defaults.headers.common['Authorization'] = token;
    return data;
}
async function registration(email, password, password_confirmation, name) {
    const resp = await axios.post('auth/register', { email, password, password_confirmation, name })
    const data = resp.data.data
    const token = data.token_type + ' ' + data.access_token
    data.token = token
    axios.defaults.headers.common['Authorization'] = token;
    return data;
}

export default {
    loginWithEmail,
    registration
}