import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/it-companies',
    name: 'IT Companies',
    component: () => import('../views/company/ItCompanies.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/registrate',
    name: 'registrate',
    component: () => import('../views/Registrate.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('../views/Policy.vue')
  },
  {
    path: '/news/create',
    name: 'create post',
    component: () => import('../views/news/Create.vue')
  },
  {
    path: '/news/edit/:id',
    name: 'edit post',
    component: () => import('../views/news/Edit.vue')
  },
  {
    path: '/news/:id',
    name: 'post',
    component: () => import('../views/news/News_id.vue')
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('../views/user/User_id.vue')
  },
  {
    path: '/company/:id',
    name: 'company',
    component: () => import('../views/company/Company_id.vue')
  },
  {
    path: '/personal',
    name: 'personalPage',
    component: () => import('../views/user/Personal.vue')
  },
  {
    path: '/events',
    name: 'events',
    component: () => import('../views/events/Events.vue')
  },
  {
    path: '/events/create',
    name: 'eventCreate',
    component: () => import('../views/events/Create.vue')
  },
  {
    path: '/events/:id',
    name: 'event',
    component: () => import('../views/events/Event_id.vue')
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('../views/Notifications.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          // resolve(Object.assign({behavior: 'smooth'}, savedPosition))
          console.log(to, from, savedPosition);
          resolve(savedPosition)
        }, 100)
      })
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
