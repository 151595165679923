<template>
  <div>
    <div class="new">
      <div class="tags-row row">
        <div
          @click="searchByTag(tag)"
          class="tag"
          v-for="tag in postInfo.categories"
          :key="tag.id + tag.name_uz"
        >
          {{ tag["name_" + currLang] }}
        </div>
      </div>
      <h5 class="description mb-3 ml-0">{{ postInfo.description }}</h5>
      <img
        v-if="postInfo.thumbnail"
        :src="postInfo.thumbnail"
        class="post-image"
        :alt="postInfo.title"
      />
      <b-embed
        v-if="postInfo.preview"
        class="pb-2"
        type="iframe"
        aspect="16by9"
        :src="postInfo.preview + '?autoplay=1'"
        allowfullscreen
      ></b-embed>
      <div class="content ml-1" v-html="content" />
      <div class="actions-row mt-4">
        <div
          v-if="postInfo.status != 'Draft'"
          :class="['likes', !$store.state.loggedIn ? 'mutted' : '']"
          @click="toggleLike()"
        >
          <b-icon-heart v-if="!postInfo.is_liked" class="icon" />
          <b-icon-heart-fill v-else />
          <span>{{ postInfo.likes_count }}</span>
        </div>
        <div
          v-if="postInfo.status != 'Draft'"
          class="share"
          @click.prevent="share()"
        >
          <b-icon-share class="icon" />
        </div>
        <div v-if="postInfo.status != 'Draft'" class="views mutted ml-auto">
          <b-icon-eye class="icon mt-1" />
          <span class="mt-1">{{ postInfo.view_count }}</span>
        </div>
        <div
          v-if="user.id == postAuthorId"
          @click="deletePost()"
          :class="['delete', postInfo.status == 'Draft' ? 'ml-auto' : '']"
        >
          <img src="../assets/trash.svg" alt="" />
        </div>
        <router-link
          :to="
            '/news/edit/' +
            postInfo.id +
            (postInfo.status == 'Draft' ? '?draft=true' : '')
          "
          v-if="user.id == postAuthorId"
          class="edit text-dark"
        >
          <b-icon-pencil-square />
        </router-link>
      </div>
      <div class="bottom">
        <a
          @click.prevent="
            openPage((verified ? '/company/' : '/user/') + autor.id)
          "
          :href="(verified ? '/company/' : '/user/') + autor.id"
          class="author d-flex align-items-center text-dark"
        >
          <div
            class="profile-pic"
            :style="
              autor.avatar ? { 'background-image': `url(${autor.avatar})` } : {}
            "
          />
          <p class="author-name">{{ autor.name }}</p>
          <b-icon-check-circle-fill
            v-if="verified"
            class="ml-2"
            variant="primary"
          />
        </a>
        <p class="time">{{ parseTime(postInfo.created_at) }}</p>
        <b-dropdown
          v-if="translations"
          :text="t('translations')"
          variant="primary"
          class="ml-auto translate-dropdown"
        >
          <b-dropdown-item
            v-for="locale in Object.keys(translations)"
            :key="'postTranslation' + locale"
            :href="translations[locale]"
          >
            <img
              :src="langIcons[locale]"
              :alt="locale + ' icon'"
              class="lang-icon mr-2"
            />
            {{ locale }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <comments
        v-if="postInfo.status != 'Draft'"
        :id="postInfo.id"
        :commentsAmount="postInfo.comments_count"
        class="comments-block"
      />
    </div>
  </div>
</template>

<script>
import parseTime from "../utils/parseTime";
import Comments from "./Comments.vue";
import { lang } from "../plugins/translator";
import en from "@/assets/uk.png";
import uz from "@/assets/uzbekistan.png";
import ru from "@/assets/russia.png";

export default {
  components: { Comments },
  metaInfo() {
    return {
      title: this.postInfo.title,
      meta: [
        { name: "description", content: this.postInfo.description },
        { property: "og:title", content: this.postInfo.title },
        { property: "og:site_name", content: "techvibe" },
        { property: "og:image", content: this.postInfo.thumbnail },
        { property: "og:type", content: "website" },
      ],
    };
  },
  data() {
    return {
      verified: false,
      postAuthorId: -1,
      currLang: lang,
      langIcons: { en, uz, ru },
    };
  },
  methods: {
    openPage(href) {
      this.postModal.hide();
      this.enableScrollBar();
      this.$router.push(href);
    },
    parseTime: (time) => parseTime(time),
    async deletePost() {
      if (confirm(this.t("sure2DeletePost"))) {
        await this.axios.delete("news/destroy/" + this.postInfo?.id);
        this.warning(this.t("postDeleted"));
        this.$router.push("/personal");
      }
    },
    searchByTag(tag) {
      this.postModal.hide();
      this.$router.push(`/?tagId=${tag.id}`);
    },
    async toggleLike() {
      if (!this.$store.state?.loggedIn) {
        this.warning(this.t("login2Like"));
        return;
      }
      try {
        const resp = await this.axios.post("likes", {
          news_id: this.postInfo?.id,
        });
        if (resp.data.message == "Like was remove") {
          this.postInfo.is_liked = false;
          this.postInfo.likes_count--;
        } else {
          this.postInfo.is_liked = true;
          this.postInfo.likes_count++;
        }
      } catch (ignored) {
        0;
      }
    },
    share() {
      this.shareUrl("/news/" + this.postInfo.id, this.postInfo.title);
    },
  },
  computed: {
    translations() {
      const temp = {};
      const locales = this.postInfo?.locales || {};
      Object.keys(locales).forEach((locale) => {
        if (locales[locale]) {
          temp[locale] = "/news/" + locales[locale];
        }
      });
      return Object.values(temp).length > 1 ? temp : null;
    },
    autor() {
      if (this) {
        this.$forceUpdate();
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.postAuthorId = this.postInfo.user.id;
        if (this.postInfo.company) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.verified = true;
          return this.postInfo.company;
        } else {
          return this.postInfo.user;
        }
      } else return {};
    },
    user() {
      if (this) {
        this.$forceUpdate();
        return this.$store?.state?.userInfo || {};
      } else return {};
    },
    content() {
      if (this) {
        this.$forceUpdate();
        if (this?.postInfo?.content) {
          return this.postInfo.content.replaceAll(
            /<p(\s(style).+)*>&nbsp;<\/p>/g,
            ""
          );
        } else {
          return ``;
        }
      } else return ``;
    },
  },
  props: ["postInfo"],
};
</script>

<style lang="scss" scoped>
.lang-icon {
  width: 20px;
  border-radius: 0 !important;
}
.top,
.bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .verified {
    margin-left: 10px;
    padding: 2px 7px;
    font-size: 12px;
    border-radius: 50%;
    background-color: #4e92f1;
    font-weight: bold;
    color: white;
    height: max-content;
  }
  .author {
    padding: 0 10px 0 0;
  }
  .author:hover {
    cursor: pointer;
    border-radius: 20px;
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.082);
  }
  p {
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 0;
  }
  .time {
    margin-left: 3%;
    font-size: 14px;
    opacity: 0.4;
  }
}
.bottom {
  margin-top: 10px;
  padding-top: 10px;
}
.tags-row {
  padding-left: 10px;
  .tag {
    cursor: pointer;
    font-weight: bold;
    background-color: #cecece;
    border-radius: 5px;
    color: white;
    margin: 5px;
    padding: 0 8px;
    width: max-content;
  }
  .tag:hover {
    background-color: rgb(172, 172, 172);
  }
}
.thumbnail {
  border-radius: 10px;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
}
.post-image {
  border-radius: 10px;
  width: 100%;
}
.description {
  max-width: 100%;
  word-wrap: break-word;
  margin: 10px 5px;
  margin-bottom: 20px;
}
.actions-row {
  display: flex;

  .icon {
    font-size: 20px;
  }
  span {
    font-weight: bold;
    margin-left: 4px;
    font-size: 16px;
    color: black;
  }
  .delete {
    img {
      width: 20px;
    }
  }
  .edit {
    svg {
      height: 20px;
      width: 20px;
      margin-bottom: -3px;
    }
  }
  .views {
    background-color: transparent !important;
  }
}
.actions-row > * {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
}
.actions-row > *:hover {
  background-color: rgba(128, 128, 128, 0.082);
}
.comments-block {
  width: 100%;
  margin-top: 30px;
  left: -0px;
  position: absolute;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .comments-block {
    margin-top: 20px !important;
  }
}
@media (max-width: 540px) {
  .bottom {
    .profile-pic {
      min-width: 30px;
      max-width: 30px;
      height: 30px;
    }
    .verified {
      transform: scale(0.7);
      opacity: 0.8;
    }
  }
  .tags-row {
    margin-top: 10px;
    opacity: 0.6;
    font-size: 14px;
  }
  .thumbnail {
    display: block;
    margin-left: -16px !important;
    margin-right: -16px !important;
    border-radius: 0 !important;
  }
  .description {
    font-size: 16px;
  }
  button.main-outlined {
    padding: 5px 15px;
    font-size: 14px;
  }
  .actions-row > * {
    margin: 0px !important;
  }
  .views {
    margin-top: -5px !important;
  }
  .share,
  .comments {
    img {
      height: 20px !important;
    }
  }
}
</style>