<template>
    <div class="left-sidebar">
        <create-post v-if="$store.state.loggedIn" />
        <div class="tags-list">
            <div v-if="!tagsList.length" class="w-100 d-flex justify-content-center">
                <b-spinner variant="success"></b-spinner>
            </div>
            <router-link
                :to="`/?tagId=${tag.id}`" 
                v-for="tag in tagsList.filter((element, index) => index < 6 )" 
                :key="tag.name" 
                class="tag-iteration"
            >
                <div class="w-100 tag">
                    <div class="thumb">
                        <img :src="tag.svg" :alt="tag.name"/>
                    </div>
                    <span> {{ tag.name }}</span>
                </div>
            </router-link>
            <b-collapse id="collapse-3">
                <router-link
                    :to="`/?tagId=${tag.id}&tagName=${tag.name}`" 
                    v-for="tag in tagsList.filter((element, index) => index > 5 )" 
                    :key="tag.name" 
                    class="tag-iteration"
                >
                    <div class="w-100 tag">
                        <div class="thumb">
                            <img :src="tag.svg" :alt="tag.name"/>
                        </div>
                        <span> {{ tag.name }}</span>
                    </div>
                </router-link>
            </b-collapse>
            <b-button v-b-toggle.collapse-3 variant="white" @click="dropdownClicked = !dropdownClicked" class="toggle-dropdown shadow-none d-flex align-items-center">
                <small class="ml-3 mr-3">
                    <b-icon-chevron-down v-if="!dropdownClicked" />
                    <b-icon-chevron-up v-else />
                </small>
                <span>
                    {{ t('others') }}
                </span>
            </b-button>
        </div>
        <div class="contact-us">
            <h4 class="text-center">{{ t(`contacts`) }}</h4>
            <hr>
            <div class="logo link">
                <a href="https://it-park.uz"><img src="@/assets/it-park.svg" alt=""></a>
            </div>
            <a href="mailto:blog.it.park@gmail.com">
                <p class="mt-3 link p-2"><b-icon-chat-square-text-fill /> {{ t('writeEmail') }} </p>
            </a>
            <input type="email" class="form-control rounded-pill" value="blog.it.park@gmail.com" disabled>
            <a href="https://g.page/IT-Park-UZ?share">
                <p class="mt-3 fs-6 link p-2"> <b-icon-geo-alt-fill />{{ t('address') }}</p>
            </a>
            <a href="tel:+998-71-209-11-99">
                <p class="link p-2"> <b-icon-telephone-fill /> +998-71-209-11-99</p>
            </a>
        </div>
    </div>
</template>

<script>
import CreatePost from './CreatePost.vue';

export default {
    components: { CreatePost },
    data() {
        return {
            list: [],
            dropdownClicked: false,
        }
    },
    props: [ 'showLogout' ],
    computed: {
        tagsList() {
            this.list.forEach(category => {
                category.name = category['name_' + this.currLang]
            })
            return this.list
        }
    },
    async mounted() {
        this.list = await this.$store.dispatch('getTagsList')
    }
}
</script>

<style lang="scss" scoped>
.contact-us {
    margin-top: 10px;
    border-radius: 15px;
    background-color: white;
    padding: 5%;

    .logo {
        max-width: max-content;
        padding: 5px 15px 5px 5px;
    }
    .link:hover {
        max-width: max-content;
        border-radius: 15px;
        background-color: rgba(128, 128, 128, 0.05);
    }
    a {
        color: black !important;
    }
}
.sidebar-option {
    background-color: white;
    padding: 10px;
    margin: 10px 0;
    border-radius: 15px;

    .links {
        max-width: 790px;
        display: flex;
        margin-left: 10px;
        justify-content: flex-start;

        a {
            color: black !important;
        }
    }
    img {
        margin-right: 10px;
    }
    .icon {
        font-size: 20px;
    }
    .profile-pic {
        background-image: url('../assets/user.svg');
        min-width: 24px;
        max-width: 24px;
        border-radius: 25px;
        height: 24px;
        background-size: cover;
        background-position: center;
    }
}
.tags-list {
    border-radius: 15px;
    height: max-content;
    padding: 15px 0;
    // border: 1px solid #DCDCDC;
    background-color: white;

    .toggle-dropdown {
        text-align: left;
        font-weight: bold;
        width: 100%;
        padding: 10px !important;
        margin: 0 !important;
        border: 0 !important;
    }
    .tag-iteration {
        text-decoration: none;
    }
    .tag {
        color: black !important;
        padding: 10px 15px;
        cursor: pointer;
        // margin-bottom: 7px;
        display: flex;
        align-items: center;
        font-weight: bold;

        .thumb { 
            display: flex;
            justify-content:center;
            align-items:center;
            width: 40px;
            min-width: 40px;

            img {
                max-height: 28px;
            }
        }
        span {
            width: max-content;
            border-radius: 5px;
            transition: all 0.4s;
            overflow: hidden;
            padding: 3px 10px;
            background-color: transparent;
        }
        span:hover {
            background-color: #323232;
            color: white;
        }
    }
    .tag-iteration:nth-child(even) {
        .tag {
            background: #F7F7F7;
        }
    }
    .underline {
        margin: 0px auto;
        width: 80%;
        height: 2px;
        background-color: rgba(99, 99, 99, 0.082);
    }
    .tag-iteration:last-child > .underline {
        display: none;
    }
    .tag-count {
        font-size: 12px;
        margin-left: auto;
        text-align: center;
        padding: 3px 10px;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.075);
        color: black;
    }
}
</style>