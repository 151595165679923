<template>
    <div class="comment">
        <div class="d-flex" v-if="comment.id">
            <a v-if="!comment.company" 
                @click.prevent="openPage(`/user/${comment.user_id}`)" 
                :href="`/user/${comment.user_id}`"
            >
                <div class="profile-pic mt-2" :style="comment.user_avatar ? {'background-image': `url(${comment.user_avatar})`} : {}" />
            </a>
            <a v-else 
                @click.prevent="openPage(`/company/${comment.company.id}`)"
                :href="`/company/${comment.company.id}`"
            >
                <div class="profile-pic mt-2" :style="comment.company.avatar ? {'background-image': `url(${comment.company.avatar})`} : {}" />
            </a>
            <div class="ml-3 w-100">
                <a v-if="!comment.company" @click.prevent="openPage(`/user/${comment.user_id}`)" :href="`/user/${comment.user_id}`">
                    <span class="link">{{ comment.user_name }}</span>
                </a>
                <a v-else @click.prevent="openPage(`/company/${comment.company.id}`)" :href="`/company/${comment.company.id}`">
                    <span class="link">{{ comment.company.name }}</span>
                </a>

                <small class="ml-3 text-muted">{{ parseTime(comment.created_at) }}</small>
                <p>{{comment.content}}</p>
                <div class="dropdown-section mb-2 w-100">
                    <div class="row align-items-center mx-0">
                        <label v-if="$store.state.loggedIn" class="mr-3 mb-2" :for="'comment_' + comment.id"> 
                            <small @click="reply(comment.company.name || comment.user_name)" class="link reply text-muted">{{ t('reply') }}</small> 
                        </label>
                        <small v-if="comment.subcomment.length && !collapseVisible"  @click="collapseVisible = true" class="text-secondary link mb-1"> 
                            <b-icon-arrow-down-short /> 
                            {{ t('commentSee') }} ( {{ comment.subcomment.length }} )
                        </small>
                        <small v-else-if="comment.subcomment.length"  @click="collapseVisible = false" class="text-secondary link mb-1"> 
                            <b-icon-arrow-up-short /> {{ t('hide') }}
                        </small>
                        <small 
                            @click="deleteComent(comment.id, 0)"
                            class="text-danger link mb-1 ml-2"
                            v-if="$store.state.loggedIn && ($store.state.userInfo.id == comment.user_id || $store.state.userInfo.role.includes('admin'))"
                        >
                            {{ t('delete') }}
                        </small>
                    </div>
                    <b-collapse :visible="collapseVisible" :id="'comment_' + comment.id" class="mt-2 subcomments w-100">
                        <div class="subcomment d-flex mb-3" v-for="subcom in comment.subcomment" :key="'comment_' + comment.id + ` > ` + subcom.id">
                            <a v-if="!subcom.company" @click.prevent="openPage(`/user/${subcom.user_id}`)" :href="`/user/${subcom.user_id}`">
                                <div class="profile-pic mt-2" :style="subcom.user_avatar ? {'background-image': `url(${subcom.user_avatar})`} : {}" />
                            </a>
                            <a v-else @click.prevent="openPage(`/company/${subcom.company.id}`)" :href="`/company/${subcom.company.id}`">
                                <div class="profile-pic mt-2" :style="subcom.company.avatar ? {'background-image': `url(${subcom.company.avatar})`} : {}" />
                            </a>
                            <div class="ml-3">
                                <a v-if="!subcom.company" @click.prevent="openPage(`/user/${subcom.user_id}`)" :href="`/user/${subcom.user_id}`">
                                    <span class="link">{{ subcom.user_name }}</span>
                                </a>
                                <a v-else @click.prevent="openPage(`/company/${subcom.company.id}`)" :href="`/company/${subcom.company.id}`">
                                    <span class="link">{{ subcom.company.name }}</span>
                                </a>
                                <small class="ml-3 text-muted">{{ parseTime(subcom.created_at) }}</small>
                                <p class="mb-1">{{subcom.content}}</p>
                                <small 
                                    v-if="$store.state.loggedIn" 
                                    @click="reply(subcom.company.name || subcom.user_name)" 
                                    class="link reply text-muted"
                                >{{ t('reply') }}</small>
                                <small 
                                    @click="deleteComent(subcom.id, 1)"
                                    class="text-danger link mb-1 ml-2" 
                                    v-if="$store.state.loggedIn && ($store.state.userInfo.id == comment.user_id || $store.state.userInfo.role.includes('admin'))"
                                >
                                    {{ t('delete') }}
                                </small>
                            </div>
                        </div>
                        <div class="d-flex w-100" v-if="$store.state.loggedIn" >
                            <b-form-input
                                :id="'comment_' + comment.id"
                                v-model="replyText"
                                class="reply-coment"
                                :placeholder="t(`reply`)"
                                :ref="'reply' + comment.id"
                            />
                            <b-button @click="submit()" variant="success" class="reply-btn text-white">
                                <b-icon-arrow-up-circle-fill />
                            </b-button>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import parseTime from '../utils/parseTime'

export default {
    data() {
        return {
            collapseVisible: false,
            replyText: '',
        }
    },
    methods: {
        parseTime: (time) => parseTime(time),
        reply(name) {
            this.replyText = name + ', '
            this.collapseVisible = true
            setTimeout(() => {
                const replyInput = this.$refs['reply' + this.comment.id]
                replyInput.focus()
            }, this.collapseVisible ? 0 : 500)
        },
        async submit() {
            if(!this.replyText) {
                this.warning(this.t('commentCantBeEmpty'))
                return ;
            } else if(this.replyText.length < 2) {
                this.warning(this.t('commentHasNoWords'))
                return ;
            }
            const resp = await this.axios.post('comments', {
                news_id: this.id,
                content: this.replyText,
                type: 1,
                comment_id: this.comment.id
            })
            this.comment.subcomment.push(resp.data.data)
            this.replyText = ''
        },
        async deleteComent(commId, type) {
            if(this.$store.state.userInfo.role.includes('admin'))
                this.axios.delete(`admin/comments/${ commId }/${ type }`)
            else
                this.axios.delete(`comments/${ commId }/${ type }`)
            if(type == 0) {
                this.comment = {}
            } else if(type == 1) {
                const subcomments = this.comment.subcomment
                for(let i = 0; i < subcomments.length; i++) {
                    const subcom = subcomments[i]
                    if(subcom.id == commId) {
                        subcomments.splice(i, 1)
                    }
                }
            }
        },
        openPage(href){ 
            this.postModal.hide()
            this.enableScrollBar() 
            this.$router.push(href)
        },
    },
    props: [ 'comment', 'id' ]
}
</script>

<style scoped lang="scss">
    small {
        font-size: 14px;
        color: rgb(163, 163, 163) !important;
    }
    .comment {
        padding-top: 5px;
        border-bottom: 2px solid rgba(128, 128, 128, 0.075);

        .profile-pic {
            min-width: 40px;
            min-height: 40px;
            width: 40px;
            height: 40px;
        }
        .link {
            cursor: pointer;
            margin: -5px -10px;
            padding: 5px 10px;
            border-radius: 8px;
        }
        .link:hover {
            background-color: rgba(128, 128, 128, 0.05);
        }
        a {
            height: max-content;
            width: max-content;
        }
        p {
            font-size: 18px;
        }
        .subcomment {
            .profile-pic {
                min-width: 30px;
                min-height: 30px;
                width: 30px;
                height: 30px;
            }
            p {
                font-size: 16px;
            }
        }
        .reply {
            cursor: pointer;
        }
        .reply-coment {
            opacity: 0.6;
            margin-left: 10px;
            margin-right: -35px;
            width: calc(100% + 35px);
            padding-right: 50px;
            border-radius: 15px;
        }
        .reply-coment:focus {
            opacity: 1.0;
        }
        .reply-btn {
            position: relative;
            left: 0px;
            margin-top: 1px;
            background-color: var(--itpark) !important;
            border: none;
            border-radius: 15px;
        }
    }
    @media (max-width: 540px) {
        .dropdown-section {
            margin-left: -30px;
        }
        .reply-coment {
            margin-left: -20px;
            margin-right: -80px !important;
            width: calc(100% + 80px);
            padding-right: 50px !important;
            border-radius: 15px;
        }
        .reply-btn {
            left: 35px !important;
        }
    }
</style>