import translator from '@/plugins/translator'
const t = translator.t

export default function parseTime(timeStr) {
    const oneDay = 24 * 60 * 60 * 1000
    const currDate = Math.floor(Date.now() / oneDay ) * oneDay;
    const postedTime = new Date(timeStr).getTime()
    if(currDate <= postedTime) { // Today
        // const time = new Date(postedTime).toString().split(' ')[4].split(':');
        // time.splice(time.length - 1, 1)
        // describedTime = 'bugun ' + time.join(':')

        return t('today')
    } else if(currDate <= postedTime + oneDay) { // Yesterday
        // const time = new Date(postedTime).toString().split(' ')[4].split(':');
        // time.splice(time.length - 1, 1)
        // describedTime = 'kecha ' + time.join(':')

        return t('yesterday')
    } else if(currDate <= postedTime + 30 * oneDay) { // N days ago
        const days = Math.floor( (currDate - postedTime) / oneDay ) + 1
        return days + t('daysBefore')
    } else if(currDate <= postedTime + 365 * oneDay ) { // More than a month ago
        return t('moreThanMonthBefore')
    } else {
        return t('moreThanYearBefore') // More than a year ago
    }
}