<template>
  <div class="home">
    <left-sidebar class="left-sidebar" />
    <div class="main">
      <h5 class="itpark-news-header">{{ t("itparkNews") }}</h5>
      <div class="itpark-news">
        <a
          target="_blank"
          :href="'https://it-park.uz/ru/itpark/news/' + newInfo.slug"
          v-for="newInfo in itParkNews"
          :key="newInfo.id"
          class="itpark-new"
          rel="noopener"
          :hreflang="currLang"
        >
          <div
            class="thumbnail"
            :style="{ 'background-image': `url(${newInfo.thumbnail})` }"
          />
          <div class="it-park-new-info">
            <h6 class="p-2">{{ newInfo.title }}</h6>
            <hr class="w-75 m-1 mx-auto" />
            <small class="p-2 text-muted mt-auto">{{
              parseTime(newInfo.created_at)
            }}</small>
          </div>
        </a>
      </div>
      <div class="news-bar d-flex justify-content-between">
        <template v-if="tabName">
          <h5 class="close-tag" @click="closeSearch">{{ tabName }} ✖</h5>
        </template>
        <template v-else>
          <h5 class="articles-title">{{ t("news") }}</h5>
          <span class="settings-part">
            <span v-b-modal.modal-center class="settings">
              <b-icon-gear-fill class="mr-1" />
              <span>{{ t("settings") }}</span>
            </span>
            <div class="setting-switch">
              <b-dropdown
                :text="t('popular')"
                :class="[
                  'popular',
                  'ml-3 mr-2',
                  famounsFilter ? 'active-filter' : '',
                ]"
              >
                <b-dropdown-item @click="setFilter('day')">{{
                  t("withinDay")
                }}</b-dropdown-item>
                <b-dropdown-item @click="setFilter('week')">{{
                  t("withinWeek")
                }}</b-dropdown-item>
                <b-dropdown-item @click="setFilter('month')">{{
                  t("withinMonth")
                }}</b-dropdown-item>
                <b-dropdown-item @click="setFilter('year')">{{
                  t("withinYear")
                }}</b-dropdown-item>
              </b-dropdown>
              <div
                @click="setFilter(null)"
                :class="['d-inline', !famounsFilter ? 'active-filter' : '']"
              >
                <button class="fresh">{{ t("fresh") }}</button>
              </div>
            </div>
          </span>
        </template>
      </div>
      <template v-for="(post, index) in news">
        <add
          class="add"
          v-if="post && post.isAdd"
          :addData="post"
          :key="`post ${index}`"
        />
        <post v-else-if="post" :postInfo="post" :key="`post ${index}`" />
      </template>
      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner">
          <b-spinner class="m-4" variant="success"></b-spinner>
        </div>
        <div slot="no-more" class="m-4 fw-bold">
          {{ t("noMorePosts") }} <b-icon-emoji-smile-upside-down />
        </div>
        <div slot="no-results" class="m-4 fw-bold">
          {{ t("postsNotFound") }} <b-icon-emoji-neutral />
        </div>
      </infinite-loading>
    </div>
    <right-sidebar />
    <b-modal
      id="modal-center"
      centered
      hide-footer
      :title="t('settings')"
      @show="openSetting()"
    >
      <div class="row mb-3">
        <div
          class="col-12 col-md-6 my-2"
          v-for="category in params.settingsCategory"
          :key="'settingCategory' + category.id"
        >
          <b-form-checkbox v-model="category.show">
            <div class="d-flex category-descr">
              <div
                class="thumb d-flex justify-content-center align-items-center"
              >
                <img :src="category.svg" class="thumb-icon" />
              </div>
              {{ category.name }}
            </div>
          </b-form-checkbox>
        </div>
      </div>
      <hr />
      <b-form-checkbox v-model="params.subscribe">
        {{ t("onlySubscribed") }}
      </b-form-checkbox>
      <hr />
      <div class="d-flex justify-content-start">
        <button class="main mt-3" @click.stop.prevent="setParams()">
          {{ t("change") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Post from "@/components/Post";
import InfiniteLoading from "vue-infinite-loading";
import LeftSidebar from "../components/LeftSidebar.vue";
import RightSidebar from "../components/RightSidebar.vue";
import Add from "../components/Add.vue";
import parseTime from "../utils/parseTime";
import cookies from "vue-cookies";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: "Techvibe - " + this.t("mainPageTitle"),
      meta: [
        { name: "description", content: this.t("mainPageDescription") },
        { property: "og:title", content: this.t("mainPageDescription") },
        { property: "og:site_name", content: "Techvibe" },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      postInfo: {},
      loaderState: {},
      params: {},
      page: 0,
      newsList: [],
      itParkNews: [],
      tabName: "",
    };
  },
  watch: {
    $route() {
      this.newsList = [];
      this.page = 0;
      window.scroll(0, 0);
      this.$forceUpdate();
      this.loaderState?.reset();
    },
  },
  async mounted() {
    this.params = cookies.get("params") || {};
    this.itParkNews = await this.$store.dispatch("getItParkNews");

    const { tagId, searchText } = this.$route?.query;
    const { state } = this.$store;
    if (!tagId && !searchText && state?.newsList?.length) {
      this.newsList = state.newsList;
      this.page = state.lastPage;
    }
  },
  computed: {
    news() {
      if (this) {
        this.$forceUpdate();
        const news = [...this.newsList];
        const addsList = this.$store.state.addsList;
        let addIndex = 0;
        for (
          let i = 1;
          i < this.newsList.length && addIndex < addsList.length;
          i += 3
        ) {
          const add = addsList[addIndex];
          add.isAdd = true;
          news.splice(i, 0, add);
          addIndex++;
        }
        return news;
      } else return [];
    },
    famounsFilter() {
      const params = cookies.get("params") || {};
      return params.famous;
    },
  },
  methods: {
    parseTime: (time) => parseTime(time),
    async filterChanges() {
      const { tagId, searchText } = this.$route?.query;

      if (tagId) {
        this.getNewsByTag(tagId);
      } else if (searchText) {
        this.searchBySlug(searchText);
      } else {
        this.getNews();
      }
    },
    setFilter(time) {
      const params = cookies.get("params") || {};
      if (time) {
        params.famous = true;
        params.time = time;
      } else {
        delete params.famous;
        delete params.time;
      }
      cookies.set("params", params);
      location.reload();
    },
    infiniteHandler($state) {
      this.loaderState = $state;
      this.page++;
      this.filterChanges();
    },
    async searchBySlug(searchText) {
      let searchResp;
      try {
        searchResp = await this.axios({
          url: "news/search/" + searchText,
          params: {
            locale: this.currLang,
            page: this.page,
          },
        });
      } catch (ignored) {
        this.$router.push("/");
        return;
      }
      const newsList = searchResp.data?.data || [];
      if (newsList.length) {
        this.newsList.push(...newsList);
        this.loaderState.loaded();
        this.tabName = searchText;
      } else {
        if (!this.newsList.length) {
          this.tabName = this.t("postsNotFound");
        }
        this.loaderState.complete();
      }
    },
    openSetting() {
      const allTags = [...this.$store.state.tagsList];
      const category = this.params.category || [];
      if (category.length) {
        for (let i = 0; i < allTags.length; i++) {
          const tag = allTags[i];
          if (category.indexOf(tag.id) != -1) {
            tag.show = true;
          } else {
            tag.show = false;
          }
        }
      }
      this.params.settingsCategory = allTags;
      this.$forceUpdate();
    },
    closeSearch() {
      this.$router.push("/");
      this.tabName = "";
      window.scroll(0, 0);
    },
    async getNews() {
      let newsList;
      this.params.page = this.page;
      const tempParams = Object.assign({}, this.params);
      delete tempParams.settingsCategory;
      newsList = await this.$store.dispatch("getFilteredNews", tempParams);
      if (newsList.length) {
        this.newsList.push(...newsList);
        this.$store.commit("setPage", this.page);
        this.$store.commit("setNewsList", this.newsList);
        this.loaderState.loaded();
      } else {
        this.loaderState.complete();
      }
    },
    setParams() {
      Object.keys(this.params).forEach((paramName) => {
        if (!this.params[paramName]) {
          delete this.params[paramName];
        }
      });
      if (this.params.settingsCategory?.length) {
        this.params.category = [];
        for (const category of this.params.settingsCategory) {
          if (category.show) {
            this.params.category.push(category.id);
          }
        }
      }
      delete this.params.settingsCategory;
      cookies.set("params", JSON.stringify(this.params), 365 * 24 * 60 * 3600);
      location.reload();
    },
    async getNewsByTag(tagId) {
      const resp = await this.axios({
        method: "GET",
        url: "public/news/category/" + tagId,
        params: {
          locale: this.currLang,
          page: +this.page,
        },
      });
      const tagName = await this.$store.dispatch("getTagName", tagId);
      const newsList = resp.data.data;
      if (newsList.length) {
        this.newsList.push(...newsList);
        this.loaderState.loaded();
      } else {
        this.loaderState.complete();
      }
      this.tabName = "#" + tagName;
      document.title = "#" + tagName;
    },
  },
  components: {
    Post,
    InfiniteLoading,
    LeftSidebar,
    RightSidebar,
    Add,
  },
};
</script>
<style lang="scss" scoped>
.itpark-news-header {
  color: black;
  font-weight: 550;
  padding-left: 10px;
  margin-top: 40px;
}
.itpark-news {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .itpark-new {
    cursor: pointer;
    transition: all 0.5s;
    background-color: white;
    width: calc(100% / 3 - 1.4%);
    margin-right: 2%;
    padding-bottom: 10px;
    border-radius: 12px;
    box-shadow: 0px 0 0 transparent;

    h5,
    h6,
    p {
      text-decoration: none !important;
      color: black !important;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 45px;
    }

    .thumbnail {
      width: 100%;
      height: 200px;
      border-radius: 12px 12px 0 0;
      background-color: grey;
      background-size: cover;
      background-position: center;
    }
  }
  .itpark-new:last-child {
    margin-right: 0 !important;
  }
  .itpark-new:hover {
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  }
}
.thumb-icon {
  max-height: 100%;
  max-width: 100%;
}
.news-bar {
  align-items: center;
  color: black;
  padding: 0 10px;
  margin-top: 30px;
  margin-bottom: 12px;

  .setting-switch {
    display: inline;
  }
  .settings {
    margin: -7px;
    padding: 7px;
    border-radius: 8px;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-underline-offset: 3px;
    color: #a39c9c;
    span {
      color: #323232;
    }
  }
  .fresh {
    padding: 3px 10px;
    border-radius: 5px;
    border: none;
  }
  .settings:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .close-tag {
    cursor: pointer;
    color: var(--itpark);
  }
  * {
    font-weight: bold !important;
    margin-bottom: 0;
  }
  button {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
.category-descr {
  position: relative;
  align-items: center;
  top: -8px;

  .thumb {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    // background-color: grey;
    border-radius: 50px;
    background-size: cover;
    background-position: center;
  }
}
.add {
  display: none;
}
@media (min-width: 1200px) {
}
@media (max-width: 767px) {
  .itpark-news {
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .itpark-new,
    .itpark-new:last-child {
      padding-left: 5px;
      padding-bottom: 0 !important;
      display: flex;
      margin: 7px 0 0 0 !important;
      min-height: max-content !important;
      width: 100% !important;
      border-radius: 0 !important;
      box-shadow: none !important;

      .thumbnail {
        width: 200px;
        height: 200px;
        border-radius: 0px !important;
      }
      .it-park-new-info {
        width: calc(100% - 200px);
        display: flex;
        flex-direction: column;

        h6 {
          font-size: 18px;
          height: 155px;
        }
        small {
          margin-left: auto;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .itpark-news-header {
    margin-top: 20px;
    margin-bottom: -10px !important;
  }
  .itpark-new,
  .itpark-new:last-child {
    .thumbnail {
      height: 100px !important;
      width: 100px !important;
    }
    .it-park-new-info {
      width: calc(100% - 100px) !important;

      h6 {
        font-size: 16px !important;
        height: 64px !important;
      }
      small {
        padding: 0 5px !important;
      }
    }
  }
  .news-bar {
    margin-top: 15px;
    margin-bottom: 5px;
    justify-content: center !important;

    h5 {
      margin-bottom: 10px;
    }
    .articles-title {
      display: none;
    }
    .settings-part {
      .settings > span {
        display: none;
      }
      .setting-switch {
        margin-top: 10px;
        border-radius: 15px;

        .active-filter button {
          border-radius: 15px !important;
        }
      }
    }
  }
  .create-text {
    display: none;
  }
}
</style>