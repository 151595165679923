<template>
    <div class="sidebar-option single" v-if="!$store.state.userHasCompany">
        <div class="links">
            <router-link to="/news/create" class="text-white">
                <b-icon-plus-circle-fill class="mr-2"/>
                <span class="create-text">{{ t('newPost') }}</span>
            </router-link>
        </div>
    </div>
    <b-dropdown v-else split class="w-100 sidebar-option">
        <template #button-content>
            <router-link to="/news/create" class="text-white">
                <b-icon-plus-circle-fill class="mr-2"/>
                <span class="create-text">{{ t('newPost') }}</span>
            </router-link>
        </template>
        <b-dropdown-item-button href="/events/create" @click.stop.prevent="$router.push('/events/create')">
            <b-icon-lightning-fill /> {{ t('newEvent') }}
        </b-dropdown-item-button>
    </b-dropdown>
</template>

<script>
export default {

}
</script>

<style scoped>
    .sidebar-option {
        background-color: var(--itpark) !important;
        padding: 10px;
        margin: 5px 0 10px 0 !important;
        border-radius: 15px;
        color: white;
    }
    .sidebar-option.single {
        display: flex;
        align-items: center;
        height: 57px !important;
    }
    .links {
        font-weight: bold;
        max-width: 790px;
        display: flex;
        margin-left: 10px;
        justify-content: flex-start;
    }
    img {
        margin-right: 10px;
    }
</style>