import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../utils/auth'
import cookies from 'vue-cookies'
import axios from 'axios'
import { lang } from '../plugins/translator'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    loginData: {},
    imageUrl: '',
    tagsList: [],
    addsList: [],
    userHasCompany: false,
    usersCompany: {},
    loggedIn: false,
    currentlyLogging: false,
    bestCompaniesList: [],
    eventsList: [],
    eventsLastPage: 0,
    newsList: [],
    itparkNews: [],
    itCompaniesList: [],
    lastPage: 0,
  },
  mutations: {
    loggingNow() {
      const loadScreen = document.getElementById('loadScreen');
      const body = document.getElementsByTagName('body')[0]
      loadScreen?.classList?.add('d-none')
      body.classList.remove('loading')
    },
    currentlyLogging(state) {
      state.currentlyLogging = true;
    },
    loggedWithCookies(state) {
      state.loggedIn = true;
    },
    setLoginData(state, obj) {
      state.loginData = obj
    },
    setUserInfo(state, obj) {
      state.userInfo = obj
      if(obj.company.name) {
        state.userHasCompany = true
        state.usersCompany = obj.company
        state.userInfo.avatar = obj.company.avatar
      }
    },
    removeNotificationsCounter(state) {
      state.userInfo.notifications_count = 0
    },
    setNewsList(state, news) {
      state.newsList = news
    },
    setPage(state, page) {
      state.lastPage = page
    },
    setAddsList(state, list) {
      state.addsList = list
    },
    openImage(state, url) {
      state.imageUrl = url
    },
    closeImage(state) {
      state.imageUrl = ''
    },
    setBestCompanies(state, arr) {
      state.bestCompaniesList = arr
    },
  },
  actions: {
    async getNewById({state, dispatch}, {id, draft}) {
      let postInfo = {}
      if(state.loggedIn) {
        if(!draft) {
          const newsResp = await axios.get('news/' + id);
          postInfo = newsResp.data.data;
        } else {
          const newsResp = await axios.get('news/moderate/identifier/' + id);
          postInfo = newsResp.data.data;
        }
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(!draft) {
            const newsResp = await axios.get(result ? 'news/' + id : 'public/news/' + id);
            postInfo = newsResp.data.data;
          } else {
            const newsResp = await axios.get('news/moderate/identifier/' + id);
            postInfo = newsResp.data.data;
          }
        })
      }
      if(!draft) {
        const news = state.newsList
        for(let i = 0; i < news.length; i++) {
          if(news[i].id == id) {
            news[i] = postInfo;
          }
        }
      }
      return postInfo
    },
    async getItParkNews({state}) {
      if(state.itparkNews.length) {
        return state.itparkNews
      } else {
        const resp = await axios.get('https://it-park.uz/api/info?locale=' + lang)
        state.itparkNews = resp.data
        return resp.data
      }
    },
    async getTagsList({state}) {
      if(state.tagsList?.length) {
        return state.tagsList
      } else {
        const tagsListResp = await axios({
          methods: 'GET',
          url: 'category',
          params: {
            locale: this.currLang
          }
        });
        const list = tagsListResp.data?.data
        state.tagsList = list
        return list
      }
    },
    async getITCompanies({state, dispatch}) {
      let list = []
      if(state.itCompaniesList.length) {
        return state.itCompaniesList
      } else if(state.loggedIn) {
        const companiesList = await axios.get('company/allActive');
        list = companiesList?.data?.data || [];
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(result) {
            const companiesList = await axios.get('company/allActive');
            list = companiesList.data.data;
          } else {
            const companiesList = await axios.get('public/company/allActive')
            list = companiesList.data.data || [];
          }
        })
      }
      state.itCompaniesList = list
      return list
    },
    async getCompanyInfo({state, dispatch}, id) {
      let info = {}
      if(state.loggedIn) {
        const companiesInfo = await axios.get(`company/private/${id}`);
        info = companiesInfo?.data?.data || {};
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(result) {
            const companiesInfo = await axios.get(`company/private/${id}`);
            info = companiesInfo.data.data;
          } else {
            const companiesInfo = await axios.get(`company/public/${id}`);
            info = companiesInfo.data.data;
          }
        })
      }
      return info
    },
    async getUserInfo({state, dispatch}, id) {
      let info = {}
      if(state.loggedIn) {
        const userInfo = await axios.get(`user/${id}`);
        info = userInfo?.data?.data || {};
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(result) {
            const userInfo = await axios.get(`user/${id}`);
            info = userInfo.data.data;
          } else {
            const userInfo = await axios.get(`/public/user/${id}`);
            info = userInfo.data.data;
          }
        })
      }
      return info
    },
    async getUserNews({state, dispatch}, {id, page}) {
      let news = null
      if(state.loggedIn) {
        const resp = await axios(`news/${id}/user?page=${page}`)
        news = resp?.data?.data || []
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(result) {
            const resp = await axios(`news/${id}/user?page=${page}`)
            news = resp?.data?.data || []
          } else {
            const resp = await axios.get(`/public/news/user/${id}?page=${page}`);
            news = resp?.data?.data || []
          }
        })
      }
      // console.log()
      return news
    },
    async getNews({state, dispatch}, page) {
      let news = {}
      if(state.loggedIn) {
        const newsResp = await axios.get('news/publish?page=' + page);
        news = newsResp.data.data;
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          const newsResp = await axios.get((result ? 'news/publish' : 'news') + `?page=${page}`);
          news = newsResp.data.data;
        })
      }
      return news
    },
    async getEventsList({state}, params) {
      if(state.eventsList?.length && params.page <= state.eventsLastPage) {
        return state.eventsList
      } else {
        const resp = await axios({
          method: 'GET',
          url: 'public/events/publish',
          params,
        })
        state.eventsLastPage = params.page
        const events = resp?.data?.data || []
        state.eventsList.push(...events)
        return events
      }
    },
    async getFilteredNews({state, dispatch}, params) {
      let news = {}
      params.locale = lang
      if(state.loggedIn) {
        const newsResp = await axios({
          method: 'GET',
          url: 'news/filters',
          params: params,
        });
        news = newsResp.data.data;
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(result) {
            const newsResp = await axios({
              method: 'GET',
              url: 'news/filters',
              params: params,
            });
            news = newsResp.data.data;
          } else {
            cookies.remove('params')
            const newsResp = await axios({
              method: 'GET',
              url: 'news',
              params: params,
            });
            news = newsResp.data.data;
          }
        })
      }
      return news
    },
    async getUserNotifications({state, dispatch}) {
      let notifications = []
      if(state.loggedIn) {
        const notificationsResp = await axios.get('notification');
        notifications = notificationsResp.data.data;
      } else {
        await dispatch('loginWithCookies').then(async function(result) {
          if(result) {
            const notificationsResp = await axios.get('notification');
            notifications = notificationsResp.data.data;
          } else {
            throw new Error('User is not logged in')
          }
        })
      }
      return notifications
    },
    getTagName({state}, id) {
      let res = ''
      if(state.tagsList?.length)
        state.tagsList.forEach(tag => {
          if(tag.id == id) {
            res = tag['name_' + lang]
            return ;
          }
        })
      return res
    },
    async loginWithCookies({dispatch, commit}) {
      commit('currentlyLogging')
      const token = await cookies.get('token');
      if(token) {
        axios.defaults.headers.common['Authorization'] = token;
        commit('loggedWithCookies')
        dispatch('receiveUserInfo')
        return true;
      }
      commit('loggingNow')
      return false
    },
    async registrate({commit, dispatch}, {email, password, password_confirmation, name}) {
      const data = await auth.registration(email, password, password_confirmation, name);
      cookies.set('token', data.token, 364 * 24 * 3600);
      commit('setLoginData', data);
      dispatch('receiveUserInfo')
    },
    async loginWithEmail({commit, dispatch}, {email, password, remember}) {
        const loginData = await auth.loginWithEmail(email, password, remember);
        if(remember) cookies.set('token', loginData.token, 364 * 24 * 3600);
        else cookies.set('token', loginData.token);
        commit('setLoginData', loginData);
        dispatch('receiveUserInfo')
    },
    async receiveUserInfo({commit}) {
      try {
        const userData = await axios.get('user');
        const data = userData.data.data;
        axios.defaults.headers.common['user_id'] = data.id;
        commit('setUserInfo', data)
        commit('loggingNow')
      } catch(ignored) {
        cookies.remove('token')
        location.reload();
      }
    }
  },
  // modules: {
  // }
})