<template>
    <section class="post">
        <div class="top">
            <router-link :to="(verified ? '/company/' : '/user/') + autor.id" class="author d-flex align-items-center">
                <div class="profile-pic" :style="autor.avatar ? {'background-image': `url(${autor.avatar})`} : {}" />
                <p class="author-name">{{ autor.name }}</p>
                <b-icon-check-circle-fill v-if="verified" class="ml-2" variant="primary" />
            </router-link>
            <p class="time">{{ parseTime(postInfo.created_at) }}</p>
            <div class="tags-row row">
                <div
                    @click="searchByTag(tag)"
                    class="tag"
                    v-for="tag in tagsList"
                    :key="tag.id + tag.name"
                >
                    {{ tag.name }}
                </div>
            </div>
        </div>
        <router-link :to="'/news/' + postInfo.id + (postInfo.status == 'Draft' ? '?draft=true' : '')">
            <h3 class="title mt-3 text-dark">{{ postInfo.title }}</h3>
            <h5 class="description text-dark" v-if="postInfo.description && postInfo.description != 'undefined'">{{ postInfo.description }}</h5>
        </router-link>
        <div class="thumbnail-part">
            <div 
                :style="postInfo.thumbnail ? {'background-image': `url(${postInfo.thumbnail})`} : {height: '0 !important'}"
                @click.prevent="$router.push('/news/' + postInfo.id + (postInfo.status == 'Draft' ? '?draft=true' : ''))"
                class="thumbnail"
            />
            <b-embed
                v-if="postInfo.preview"
                type="iframe"
                aspect="21by9"
                :src="postInfo.preview"
                class="embed"
                allowfullscreen
            ></b-embed>
        </div>
        <div class="actions-row mt-2">
            <template v-if="postInfo.status != 'Draft'">
                <div :class="['likes icon', !$store.state.loggedIn ? 'mutted' : '']" @click="toggleLike()">
                    <b-icon-heart v-if="!postInfo.is_liked" class="icon" />
                    <b-icon-heart-fill v-else />
                    <span>{{ postInfo.likes_count }}</span>
                </div>
                <div class="comments mutted" @click.prevent="showModal(true)">
                    <b-icon-chat-square-dots class="icon" />
                    <span>{{ postInfo.comments_count }}</span>
                </div>
                <div class="share" @click.prevent="share()">
                    <b-icon-share class="icon" />
                </div>
                <div class="views mutted ml-auto">
                    <b-icon-eye class="icon mt-1" />
                    <span class="mt-1">{{ postInfo.view_count }}</span>
                </div>
            </template>
            <div v-if="user.id == postAuthorId" @click="deletePost()" :class="['delete', postInfo.status == 'Draft' ? 'ml-auto' : '']">
                <img src="../assets/trash.svg" alt="">
            </div>
            <router-link 
                :to="'/news/edit/' + postInfo.id + (postInfo.status == 'Draft' ? '?draft=true' : '')" 
                v-if="user.id == postAuthorId" 
                class="edit text-dark"
            >
                <b-icon-pencil-square />
            </router-link>
        </div>
    </section>
</template>

<script>
import parseTime from '../utils/parseTime'

export default {
    data() {
        return {
            verified: false,
            postAuthorId: -1,
        }
    },
    methods: {
        parseTime: (time) => parseTime(time),
        async showModal(scroll2Comments=false) {
            const draft = this.postInfo.status == "Draft"
            this.openPost(this.postInfo.id, scroll2Comments, draft)
        },
        searchByTag(tag) {
            this.$router.push(`/?tagId=${tag.id}`)
            this.$emit('getNewsByTag')
        },
        share() {
            const { id, title } = this.postInfo
            this.shareUrl('/news/' + id, title)
        },
        async deletePost() {
            if(confirm(this.t('sure2DeletePost'))) {
                await this.axios.delete('news/destroy/' + this.postInfo?.id)
                this.warning(this.t('postDeleted'))
                if(this.$route.path == "/personal") {
                    this.$destroy();
                    this.$el.parentNode.removeChild(this.$el);
                } else 
                    this.$router.push('/personal')
            }
        },
        async toggleLike() {
            if(!this.$store.state?.loggedIn) {
                this.warning(this.t('login2Like'))
                return ;
            }
            try {
                const resp = await this.axios.post('likes', { news_id: this.postInfo.id })
                if(resp.data.message == 'Like was remove') {
                    this.postInfo.is_liked = false;
                    this.postInfo.likes_count--;
                } else {
                    this.postInfo.is_liked = true;
                    this.postInfo.likes_count++;
                }
            } catch (ignored){ 0 }
        }
    },
    computed: {
        tagsList() {
            this?.postInfo?.categories?.forEach(category => {
                category.name = category['name_' + this.currLang]
            })
            return this.postInfo.categories
        },
        autor() {
            if(this) {
                this.$forceUpdate();
                if(this.postInfo.company) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.postAuthorId = this.postInfo.user.id; 
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.verified = true;
                    return this.postInfo.company;
                } else {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.postAuthorId = this.postInfo.user.id; 
                    return this.postInfo.user
                }
            } else return {}
        },
        user() {
            if(this) {
                this.$forceUpdate();
                return this.$store?.state?.userInfo || {};
            } else return {}
        }
    },
    
    props: [
        'postInfo',
        'hideOptions'
    ],
}
</script>

<style scoped lang="scss">
    .title {
        font-weight: bold;
    }
    .post {
        overflow-x: hidden;
        text-align: start;
        background: #FFFFFF;
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        backdrop-filter: blur(32px);
        border-radius: 15px;
        padding: 16px;
        max-width: 790px;
        width: 100%;
        margin: 10px 0;
        height: max-content;

        .see {
            button {
                margin-top: 15px;
                border-radius: 5px;
            }
        }
    }
    .top, .bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .author {
            color: black;
            padding: 0 10px 0 0;
        }
        .author:hover {
            cursor: pointer;
            border-radius: 20px;
            background-color: rgba(128, 128, 128, 0.082);

            p {
                text-decoration: underline;
            }
        }
        .verified {
            margin-left: 10px;
            padding: 2px 7px;
            font-size: 12px;
            border-radius: 50%;
            background-color: #4E92F1;
            color: white;
            height: max-content;
        }
        p {
            font-weight: bold;
            margin-left: 10px;
            margin-bottom: 0;
        }
        .time {
            margin-left: 3px;
            font-size: 14px;
            opacity: 0.4;
        }
    }
    .bottom {
        margin-top: 10px;
        border-top: 2px solid rgba(128, 128, 128, 0.158);
        padding-top: 10px;
        padding-bottom: 20px;
    }
    .tags-row {
        padding-left: 10px;
        margin-left: 5px;
        .tag {
            cursor: pointer;
            font-weight: bold;
            background-color: #CECECE;
            border-radius: 5px;
            color: white;
            padding: 0 8px;
            margin: 5px;
            width: max-content;
        }
        .tag:hover {
            background-color: rgb(172, 172, 172);
        }
    }
    .thumbnail-part {
        width: calc(100% + 16px + 16px);
        margin-left: -16px;
        margin-right: -16px;
        padding: 16px;
        background-color: #F7F7F7;

        .thumbnail {
            cursor: pointer;
            border-radius: 10px;
            height: 300px;
            background-size: cover;
            background-position: center;
        }
        .embed {
            border-radius: 10px;
            overflow: hidden;
        }
    }
    .post-image {
        cursor: pointer;
        border-radius: 10px;
        width: 100%;
    }
    .description {
        max-width: 100%;
        word-wrap: break-word;
        margin: 10px 5px;
        margin-bottom: 20px;
    }
    .actions-row {
        display: flex;

        .icon {
            font-size: 20px;
        }
        span {
            font-weight: bold;
            margin-left: 4px;
            font-size: 16px;
            color: black;
        }
        .views {
            background-color: transparent !important;
        }
        .delete {
            img {
                width: 20px;
            }
        }
        .edit {
            svg {
                height: 20px;
                width: 20px;
                margin-bottom: -3px;
            }
        }
    }
    .actions-row > * {
        display: flex;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        padding: 10px;
    }
    .actions-row > *:hover {
        background-color: rgba(128, 128, 128, 0.082);
    }
    @media (max-width: 540px) {
        .top {
            .profile-pic {
                min-width: 30px;
                max-width: 30px;
                background-size: cover;
                background-position: center;
                height: 30px;
            }
            .verified {
                transform: scale(0.7);
                opacity: 0.8;
            }
        }
        .tags-row {
            opacity: 0.6;
            font-size: 14px;
        }
        .thumbnail-part {
            padding: 0;

            .thumbnail {
                display: block;
                height: 200px;
                border-radius: 0 !important;
            }
            .embed {
                border-radius: 0 !important;
            }
        }
        .description {
            font-size: 16px;
        }
        button.main-outlined {
            padding: 5px 15px;
            font-size: 14px;
        }
        .actions-row {
            margin-right: -50px;
            
            div {
                margin: 7px !important;
                padding: 2px;
            }
        }
        .share, .comments {
            img {
                height: 20px !important;
            }
        }
        .views {
            * {
                margin-top: 0 !important;
            }
        }
    }
    @media(max-width: 767px) {
        .post {
            width: 100%;
            margin-top: 2px;
            margin-bottom: 2px;
            border-radius: 0 !important;

            h3 {
                font-size: 22px;
            }
        }
    }
</style>