export default {
    // global
    mainPageTitle: "Блог о технологиях, бизнесе и многом другом",
    mainPageDescription: "Техвайб это сайт, на котором вы можете вести свой блог, писать новости, советы о технологиях в Узбекистане и во всем мире",
    change: `Изменить`,
    delete: `Удалить`,
    write: `Написать`,
    see: `Посмотреть`,
    copy: `Скопировать`,
    send: `Отправить`,
    logout: 'Выйти',
    search: `Искать`,
    popular: 'Популярные',
    share: `Поделиться`,
    others: 'Остальные',
    hide: 'Скрыть',
    image: `картинка`,
    video: 'видео',
    create: 'Создать',
    back: 'Назад',
    man: 'Мужчина',
    woman: 'Женщина',
    crop: 'Обрезать',
    theme: 'Тема',

    // timer
    today: 'сегодня',
    yesterday: 'вчера',
    daysBefore: ' дн назад',
    moreThanMonthBefore: 'более чем месяц назад',
    moreThanYearBefore: 'более года назад',

    // navbar
    login: 'Войти',
    registrate: `Регистрация`,
    mainPage: 'Главная страница',
    blogs: 'Блоги',
    itCompanies: 'IT-Компании',
    events: 'Мероприятия',
    notifications: 'Уведомления',
    uHaveNoNotifications: 'У вас уже просмотрены все уведомления',

    // sidebars
    newPost: 'Создать новую статью',
    newEvent: `Создать новое мероприятие`,
    address: `4A, улица Тепамасжит, М.Улугбекский район, город Ташкент, 100170`,
    personalPage: `Личная страница`,
    exit: `Выйти`,
    contacts: 'Контакты',
    writeEmail: 'Написать на почту',
    popularCompanies: 'Популярные Блоги',

    // news roll
    translations: 'Переводы',
    itparkNews: 'Новости IT Park',
    news: 'Статьи',
    noMorePosts: `Больше статей нет`,
    postsNotFound: `Статьи не найдены`,
    onlySubscribed: `Новости авторов на которые подписаны`,
    settings: 'Настройки',
    withinDay: `За один день`,
    withinWeek: `За одну неделю`,
    withinMonth: `За один месяц`,
    withinYear: `За один год`,
    fresh: `Свежее`,

    // comments
    reply: `Ответить`,
    newComment: `Написать новый комментарий`,
    commentSee: `Посмотреть ответы`,
    commentsCount: `Комментарии`,
    noComments: `Пока что комментариев нет, будьте первым`,

    // company & user page
    follow: `Подписаться`,
    followers: 'подписаны:',
    unfollow: `Отменить подписку`,
    userPosts: 'Опубликованные',
    moderating: 'На модерации',

    // company & user info change
    name: `Название`,
    personalName: `Имя`,
    description: `Описание`,
    ava: `Аватарка`,
    thumb: `Обложка`,
    socials: `Соц. сети`,
    password: `Пароль`,
    chooseThumb: `Выберите Обложку`,
    changeCompName: `Изменить название компании`,
    changeName: `Изменить название`,
    changeCompDescription: `Изменить описание компании`,
    changeDescription: `Изменить краткую информацию`,
    changeProfilePhoto: `Выберите фотографию профиля (аватарку)`,
    changeAva: `Изменить аватарку`,
    changeUpThumb: `Изменить Обложку`,
    changeThumb: `Изменить картинку`,
    socialLinks: `Ссылки на социальные сети`,
    submitLinks: `Изменить ссылки`,
    changePassword: `Изменить пароль`,
    changePersonalName: `Изменить имя`,
    enterNewPassword: `Введите новый пароль`,
    repeatNewPassword: `Повторите новый пароль`,
    changeInfo: `Изменить информацию`,
    deleteAcc: 'Удалить аккаунт',

    // events registration
    signUp2Event: `Записаться на мероприятие`,
    AlreadySigned2Event: 'Вы уже записались на это мероприятие',
    fullName: 'Ваши имя и фамилия',
    phone: 'Ваш телефон',
    age: 'Возраст',
    sex: 'Пол',
    city: 'Город',

    //create post & event
    enterTitle: `Введите тему тут`,
    youtubeOrMover: `Ссылка на видео в Mover или Youtube: `,
    videoUrl: `Вставьте сюда ссылку на видео`,
    enterDescription: `Тут краткое описание`,
    choosePhoto: `Выберите картинку`,
    changePhoto: `Поменять картинку`,
    newPostShort: `Новая статья`,
    tags: `Теги`,
    publicate: `Опубликовать`,
    newEventShort: `Новое мероприятие`,
    beginingDate: `Время начала`,
    endDate: `Время конца`,
    futureEvents: `Будущие мероприятия`,
    currentEvents: `Проходящие сейчас`,
    finishedEvents: `Закончившиеся мероприятия`,
    noMoreEvents: `Больше мероприятий нет `,
    eventsNotFound: `Мероприятия не найдены `,

    // login && registrate
    yourEmail: `Ваша электронная почта`,
    registration: `Регистрация`,
    yourPasword: `Ваш пароль`,
    loginGoogle: `Войти через Google`,
    rememberMe: `Запомнить меня`,
    dontRememberPassword: `Забыли пароль?`,
    noProfile: `Если у вас нет профиля, то `,
    readyToLogout: `Вы уверены что хотите выйти?`,
    yesLogout: `Да, выйти`, 
    noGetBack: `Нет, вернуться`,
    yourName: 'Ваше имя',
    doAcceptPolicy: 'Принимаю условия пользовательского соглашения',
    haveProfile: `Уже есть профиль? тогда`,

    // notifications
    "news deleted": `Новость была удалена`,
    "news publish": `Новость была опубликована`,

    // errors
    suchCompanyExists: 'Уже существует компания с таким названием',
    fillEmailField: `Заполните поля электронной почты и пароля`,
    wrongEmail: `Электронная почта набрана неправильно`,
    emailOrPasswordFilledWrong: 'Электронная почта или пароль записана неправильно',
    wrongSecondPassword: "Пароль повторен неправильно",
    enterName: "Вы должны вписать свое имя",
    enterEmail: "Введите свою электронную почту",
    acceptPolicy: "Вы должны принять пользовательское соглашение",
    suchEmailExists: "Пользователь с такой электронной почтой уже существует, если он принадлежит вам то войдите",
    imageTooBig: 'Файл картинки слишком большой',
    imageNeeded: `Статья должна иметь основную картинку`,
    titleNeeded: 'Статья должна иметь название',
    fillWithText: `Заполните статью текстом`,
    tagsNeeded: `Статья должна иметь теги`,
    descriptionNeeded: `Добавьте краткое описание`,
    addThumbnail: `Статья должна иметь основную картинку`,
    failed2openPost: `Возникла ошибка с прогрузкой статьи`,
    videoWrong: `Сслыка на видео указана неправильно`,
    errorWithEvent: 'Возникли проблемы с регистрацией',
    phoneWrong: 'Телефон набран неправильно',
    wrongPassword: `Пароль введен неправильно`,
    verifyNotRobot: `Подтвердите что вы не робот`,

    //warning
    commentCantBeEmpty: 'Комментарий не может быть пустым',
    commentHasNoWords: 'В комментарии должно быть как минимум одно слово',
    login2SeeAuthor: `Войдите чтобы посмотреть страницу автора`,
    login2See: `Войдите чтобы посмотреть`,
    login2Like: `Войдите чтобы поставить лайк`,
    enterToWriteComment: 'Войдите чтобы писать комментарии',
    passwordRetypedWrong: 'Пароль повторен неправильно',
    passwordTooShort: 'Пароль должен состоять как минимум из 8 символов',
    you: 'У вас ',
    startedFollowing: `-в списке подписок`,
    stoppedFollowing: `-теперь вне списка подписок`,
    postDeleted: 'Ваша статья была успешно удалена',

    // alert
    NameHasChanged: 'Название успешно изменено',
    personalNameHasChanged: 'Имя успешно изменено',
    descriptionHasChanged: 'Описание успешно изменено',
    avaHasChanged: 'Аватар успешно изменен',
    thumbnailChanged: 'Обложка успешно изменена',
    socialsHaveChanged: `Ссылки на соц. сети успешно обновлены`,
    passwordHasChanged: 'Пароль успешно сменен',
    newPasswordHasSent: "Ваш пароль отправлен на вашу электронную почту",
    eventPremoderating: 'Мероприятие отправлено на модерацию',
    postPremoderating: 'Статья отправлена на модерацию',
    copied2clipboard: 'Ссылка скопирована в буфер обмена',
    sure2DeletePost: 'Вы уверены что хотите удалить эту статью?',
    youRegisteredEvent: `Вы успешно зарегистрировались на это мероприятие`,


  policy: `<h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
  <p>Настоящее Соглашение является публичной офертой и определяет условия использования материалов и сервисов, размещенных на сайте в сети Интернет по адресу:www.techvibe.uz ,  посетителями и пользователями данного интернет-сайта (далее — Сайт).</p>
  <h3>1. Общие условия</h3>
  <p>1.1. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Республики Узбекистан.</p>
  <p>1.2. Пользователь вправе использовать материалы Сайта и предоставляемые на Сайте сервисы любым способом, допустимым нормами действующего законодательства Республики Узбекистан.</p>
  <p>1.3. Получение доступа к материалам Сайта и использование сервисов Сайта, означает безоговорочное согласие Пользователя с настоящей Соглашением и указанными в нем условиями обработки персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов Сайта.</p>
  <h3>2. Обязательства Пользователя</h3>
  <p>2.1. Пользователь соглашается не предпринимать действий и не оставлять комментарии и записи, которые могут рассматриваться как нарушающие узбекское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных прав, общепринятые нормы морали и нравственности, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.</p>
  <p>2.2. Использование материалов Сайта без согласия правообладателей не допускается.</p>
  <p>2.3. При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна.</p>
  <p>2.4. Администрация Сайта не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Сайте.</p>
  <p>2.5. Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами или услугами, доступными на или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.</p>
  <p> Пользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с рекламой, которая может быть размещена на Сайте.</p>
  <h3>3. Политика конфиденциальности</h3>
  <p>3.1. В рамках настоящего Соглашения под персональной информацией Пользователя понимаются:</p>
  <p>3.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учетной записи) или в процессе использования Сервисов, включая персональные данные Пользователя. Обязательная для предоставления Сервисов информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение.
  </p>
  <p>3.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения, используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная информация. </p>
  <p>3.2. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов Сайта или исполнения настоящего Соглашения, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.</p>
  <p>3.3. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</p>
  <p>3.3.1 Идентификации Пользователя, зарегистрированного на Сайте для дальнейшего заключения договора на предоставление услуг, указанных на Сайте.</p>
  <p>3.3.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.</p>
  <p>3.3.3. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
  <p>3.3.4. Создания учетной записи Пользователя.</p>
  <p>3.3.5. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта.</p>
  <p>3.3.6. Осуществления рекламной деятельности.</p>
  <p>3.4. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных сервисов.</p>
  <p>3.5. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональной информации становится общедоступной. </p>
  <p>3.6. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
  <p>3.7. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
  <p>3.8. Пользователь предоставляет Администрации Сайта право упоминания о сотрудничестве с Пользователем, оказанных Пользователю услугах, использования фрагментов результата таких услуг и имени (наименования) Пользователя для указания в портфолио Администрации Сайта (в том числе в рекламных целях).</p>
  <h3>4. Прочие условия</h4>
  <p>4.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Республики Узбекистан.</p>
  <p>4.2. В случае признания какого-либо положения или условия настоящего Соглашения недействительным, лишенным юридической силы или ничтожным судом соответствующей юрисдикции остальные положения настоящего Договора сохраняют свою силу и продолжают действовать без изменений, в то время как положение, признанное ничтожным в силу применимого закона, заменяется действительным, то есть максимально близким относительно предыдущего по своей сути и значению.</p>
  <p>4.3 Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта.</p>
  <p>4.4. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении в день размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.</p>
  `
}