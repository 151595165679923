<template>
    <div :class="['message', 'd-flex', 'align-items-center', 'px-3', 'pb-2', 'pt-3', (message.type || 'success')]" v-if="message.hasMessage && timer > -1" >
        <div class="mr-1">
            <h6 v-html="message.text"></h6>
            <router-link no-prefetch exact :to="message.path" v-if="message.path">
                <b-button @click="removeMessage()" variant="light">{{ t(`see`) }}</b-button>
            </router-link>
        </div>
        <div class="close-button py-2 px-3" @click="removeMessage()">
            ✖
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    data() {
        return {
            message: {},
            timer: 0,
        }
    },
    mounted() {
        Vue.prototype.alert = this.alert;
        Vue.prototype.warning = this.warning;
        Vue.prototype.error = this.error;
    },
    methods: {
        alert(message) {
            let text = '';
            let path = '';
            let type = '';
            if(typeof message == 'object') {
                text = message.text;
                path = message.path;
                type = message.type;
            } else if(typeof message == 'string') {
                text = message
            } else return;
            this.message = {
                hasMessage: true,
                type: type || 'success',
                text,
                path,
            }
            this.timer = 8
            this.secondamer()
        },
        secondamer() {
            setTimeout(() => {
                if(this.timer > -1) {
                    this.timer--;
                    this.secondamer();
                }
            }, 1000)
        },
        warning(message) {
            if(typeof message == 'object') {
                message.type = 'warning';
                this.alert(message);
            } else if(typeof message == 'string') {
                this.alert({ text: message, type: 'warning' })
            }
        },
        error( message) {
            if(typeof message == 'object') {
                message.type = 'danger';
                this.alert(message);
            } else if(typeof message == 'string') {
                this.alert({ text: message, type: 'danger' })
            }
        },
        removeMessage() {
            this.message = {
                hasMessage: false,
                type: 'warning',
                text: '',
                path: '',
            }
        }
    },
}
</script>

<style scoped>
.close-button {
    margin-left: 10px;
    cursor: pointer;
}
.close-button:hover {
    background-color: #27292c0c;
    border-radius: 10px;
}
.warning {
    background-color: rgb(233, 233, 121);
    color: #27292c;
}
.danger {
    background-color: rgb(170, 58, 58);
    color: white;
}
.success {
    background-color: #7DBA29;
    color: white;
}
.message {
    border-radius: 10px;
    font-weight: bold !important;
    max-width: 350px !important;
    min-height: 50px;
    position: fixed;
    z-index: 9999999999;
    top: 90px;
    right: 5vw;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.116);
}
h5, h6 {
    font-weight: 600;
}
</style>