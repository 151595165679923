export default {
    // global
    mainPageTitle: "Blog about technology, business and more",
    mainPageDescription: "Techvibe is a website where you can have own blog, write news, tips about technology in Uzbekistan and over the whole world",
    change: `Change`,
    delete: `Delete`,
    write: `Write`,
    see: `Check`,
    copy: `Copy`,
    send: `Send`,
    logout: 'Log out',
    search: `Search`,
    popular: 'Popular',
    share: `Share`,
    others: 'Others',
    hide: 'Hide',
    image: `image`,
    video: 'video',
    create: 'Create',
    back: 'back',
    man: 'Male',
    woman: 'Female',
    crop: 'Crop',
    theme: 'Theme',

      // timer
    today: 'today',
    yesterday: 'yesterday',
    daysBefore: ' days ago',
    moreThanMonthBefore: 'more than a month before',
    moreThanYearBefore: 'more than a year before',

    // navbar
    login: 'Log in',
    registrate: `Registration`,
    mainPage: 'Main page',
    blogs: 'Blogs',
    itCompanies: 'IT-Companies',
    events: 'Events',
    notifications: 'Notifications',
    uHaveNoNotifications: 'You don`t have any notifications unseen',

    // sidebars
    newPost: 'Create a new post',
    newEvent: `Create a new event`,
    address: `4A, Tepamasjit str., M.Ulugbek district, Tashkent city, 100170`,
    personalPage: `Personal page`,
    exit: `Log out`,
    contacts: 'Contacts',
    writeEmail: 'Write an email',
    popularCompanies: 'Popular blogs',

    // news roll
    translations: 'Translations',
    itparkNews: 'IT Park news',
    news: 'Articles',
    noMorePosts: `no more posts`,
    postsNotFound: `posts are not found`,
    onlySubscribed: `News from subscribed authors`,
    settings: 'Settings',
    withinDay: `Within a day`,
    withinWeek: `Within a week`,
    withinMonth: `Within a month`,
    withinYear: `Within a year`,
    fresh: `Fresh`,

    // comments
    reply: `Reply`,
    newComment: `Write a comment`,
    commentSee: `See comments`,
    commentsCount: `Comments`,
    noComments: `No comments yet, be the first`,

    // company & user page
    follow: `Follow`,
    followers: 'follows:',
    unfollow: `Unfollow`,
    userPosts: 'Published',
    moderating: 'On moderation',

    // company & user info change
    name: `Name`,
    personalName: `Name`,
    description: `Description`,
    ava: `Profile icon`,
    thumb: `Thumbnail`,
    socials: `Socials`,
    password: `Password`,
    chooseThumb: `Choose thumbnail`,
    changeCompName: `Change company name`,
    changeName: `Change name`,
    changeCompDescription: `CHange company description`,
    changeDescription: `Change description`,
    changeProfilePhoto: `Choose profile icon`,
    changeAva: `Change profile icon`,
    changeUpThumb: `Change thumbnail`,
    changeThumb: `Change picture`,
    socialLinks: `Profiles in social networks`,
    submitLinks: `Change links`,
    changePassword: `Change password`,
    changePersonalName: `Change name`,
    enterNewPassword: `Enter new Password`,
    repeatNewPassword: `Repeat new password`,
    changeInfo: `Change information`,
    deleteAcc: 'Delete account',

    // event registration
    signUp2Event: `Sign up to this event`,
    AlreadySigned2Event: 'You have already signed up to this event',
    fullName: 'Your full name',
    phone: 'Phone',
    age: 'Age',
    sex: 'Sex',
    city: 'City',

    //create post & event
    enterTitle: `Enter title here`,
    youtubeOrMover: `Link to video in Youtube or in Mover: `,
    videoUrl: 'Enter video URL',
    enterDescription: `Enter short description`,
    choosePhoto: `Choose picture`,
    changePhoto: `Change picture`,
    newPostShort: `New post`,
    tags: `Tags`,
    publicate: `Publish`,
    newEventShort: `New event`,
    beginingDate: `Starting time`,
    endDate: `End time`,
    futureEvents: `Upcoming events`,
    currentEvents: `Ongoing events`,
    finishedEvents: `Finished events`,
    noMoreEvents: `No more events `,
    eventsNotFound: `Events not found `,

    // login && registrate
    yourEmail: `Your email`,
    registration: `Registration`,
    yourPasword: `Your password`,
    loginGoogle: `Login with Google`,
    rememberMe: `Remember me`,
    dontRememberPassword: `Forgot password?`,
    noProfile: `If you don't have account, then `,
    readyToLogout: `Are tou sure you want to logout ?`,
    yesLogout: `Yes, logout`, 
    noGetBack: `No, get back`,
    yourName: 'Your name',
    doAcceptPolicy: 'Accept user terms of use',
    haveProfile: `Already have account? then`,

    // notifications
    "news deleted": `Your post was deleted`,
    "news publish": `Your post was published`,

    // errors
    suchCompanyExists: 'Company with such name already exists',
    fillEmailField: `Please fill email and password fields`,
    wrongEmail: `Email is wrong`,
    emailOrPasswordFilledWrong: 'Email or password is wrong',
    wrongSecondPassword: "Password repeated wrong",
    enterName: "You have to enter your name",
    enterEmail: "Enter your email",
    acceptPolicy: "You have to accept ",
    suchEmailExists: "There already exists user with such email, if its your then login",
    imageTooBig: 'Image file is too big',
    imageNeeded: `Article has to have the main image`,
    titleNeeded: 'Article has to have title',
    fillWithText: `Add text to the article`,
    tagsNeeded: `There has to be at least one tag`,
    descriptionNeeded: `Add description`,
    addThumbnail: `Article has to have the main image`,
    failed2openPost: `There is an error with loading this page`,
    videoWrong: `Video URL is wrong`,
    errorWithEvent: 'There are some errors with signing up',
    phoneWrong: 'Phone is wrong',
    wrongPassword: `Password is wrong`,
    verifyNotRobot: `Verify you are not a robot`,

    //warning
    commentCantBeEmpty: 'Comment can not be empty',
    commentHasNoWords: 'Comment has to have at least one word',
    login2SeeAuthor: `Login to see author's page`,
    login2See: `you have to login to see this page`,
    login2Like: `Login to be able to put like reaction`,
    enterToWriteComment: 'Login to write comments',
    passwordRetypedWrong: 'Password repeated wrong',
    passwordTooShort: 'Password has to be at least 8 symbols',
    you: 'You have',
    startedFollowing: `-on your following list`,
    stoppedFollowing: `-is now out of your following list`,
    postDeleted: 'Your post was successfully deleted',

    // alert
    NameHasChanged: 'Name has been changes successfully',
    personalNameHasChanged: 'Name has been changes successfully',
    descriptionHasChanged: 'Description has been changes successfully',
    avaHasChanged: 'Profile picture has been changes successfully',
    thumbnailChanged: 'Thumbnail has been changes successfully',
    socialsHaveChanged: `Links have been changes successfully`,
    passwordHasChanged: 'Password has been changes successfully',
    newPasswordHasSent: "Your new password will be sent to your email",
    eventPremoderating: 'Your event is sent to moderation',
    postPremoderating: 'Your article was sent to moderation',
    copied2clipboard: 'link was copied to the clipboard',
    sure2DeletePost: 'Are you sure you want to delete this post?',
    youRegisteredEvent: `You are successfully registered to this event`,


    policy: `On development`
}