<template>
    <b-modal 
        centered 
        ref="sharing" 
        hide-footer 
        :title="t('share')" 
        style="position: absolute"
        @close="closeModal"
        @hide="closeModal"
    >
        <div class="d-flex">
            <a target="_blank" :href="`https://t.me/share/url?url=${sharingUrl}&text=${text}`" @click="closeModal" class="telegram-btn">
                <img src="../assets/telegram.svg" alt="">
            </a>
            <a target="_blank" :href="'whatsapp://send?text=' + sharingUrl" @click="closeModal" class="whatsapp-btn">
                <img src="../assets/whatsapp.svg" alt="">
            </a>
        </div>
        <div class="d-flex">
            <b-input class="url-input" disabled v-model="sharingUrl" />
            <b-button variant="success" @click.prevent="copy2Clipboard()">{{ t('copy') }}</b-button>
        </div>
    </b-modal>
</template>

<script>
import Vue from 'vue'
const copy = require('clipboard-copy')

export default {
    data() {
        return {
            sharingUrl: '',
            text: ''
        }
    },
    mounted() {
        Vue.prototype.shareUrl = this.shareUrl
    },
    methods: {
        closeModal() {
            this.enableScrollBar()
            this.$refs['sharing'].hide();
        },
        copy2Clipboard() {
            copy(this.sharingUrl);
            this.closeModal()
            this.alert(this.t('copied2clipboard'))
        },
        shareUrl(url, text='') {
            event.stopPropagation();
            event.preventDefault();
            this.disableScrollBar()
            url = url[0] == '/' ? url : '/' + url;
            this.sharingUrl = location.origin + url;
            this.text = text;
            this.$refs['sharing'].show()
        },
    },
}
</script>

<style scoped lang="scss">
.telegram-btn, .whatsapp-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    background-color: #20A0E2;

    img {
        width: 50%;
        height: 50%;
    }
}
.whatsapp-btn {
    background-color: #1EBEA5;
}
.telegram-btn:hover {
    background-color: #157eb3;
}
.whatsapp-btn:hover {
    background-color: #128675;
}
.url-input {
    border-radius: 10px 0 0 10px !important;
}
.btn-success {
    background-color: var(--itpark) !important;
    border: none;
    border-radius: 0 10px 10px 0;
}
.btn-success:hover {
    box-shadow: 0 0 10px #7fb92871;
}
</style>