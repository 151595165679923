import Vue from 'vue';

const html = document.documentElement
function disableScrollBar() {
    html.classList.add('no-scroll')
}
function enableScrollBar() {
    html.classList.remove('no-scroll')
}

Vue.prototype.enableScrollBar = enableScrollBar
Vue.prototype.disableScrollBar = disableScrollBar