<template>
    <div class="comments-block">
        <div class="comments" id="opened-post-comments">
            <h5 v-if="comments.length">{{ t('commentsCount') }}: {{ commentsAmount || comments.length }}</h5>
            <h5 v-else>{{ t('noComments') }}</h5>
            <br>
            <div @click.stop.prevent="enter2Write()">
                <b-form-textarea
                    v-model="newComment"
                    :placeholder="t('newComment')"
                    rows="3"
                    no-resize
                    class="mb-2 textarea"
                />
                <div class="submit" @click="postComment()">
                    <b-button :disabled="!$store.state.loggedIn" variant="success" class="main">{{ t('write') }}</b-button>
                </div>
            </div>
            <comment 
                class="comment" 
                v-for="comment in comments"
                :key="'comment ' + comment.id"
                :comment="comment"
                :id="id"
            />
        </div>
    </div>
</template>

<script>
import parseTime from '../utils/parseTime'
import Comment from './Comment.vue'

export default {
    components: { Comment },
    data() {
        return {
            comments: [],
            newComment: '',
        }
    },
    methods: {
        parseTime: (time) => parseTime(time),
        enter2Write() {
            if(!this.$store.state.loggedIn) {
                this.warning(this.t('enterToWriteComment'))
            }
        },
        async postComment() {
            if(!this.newComment) {
                this.warning(this.t('commentCantBeEmpty'))
                return ;
            } else if(this.newComment.length < 2) {
                this.warning(this.t('commentHasNoWords'))
                return ;
            }
            const resp = await this.axios.post('comments', {
                news_id: this.id,
                content: this.newComment,
                type: 0,
            })
            this.newComment = ''
            this.comments.unshift(resp.data.data)
        }
    },
    async mounted() {
        const resp = await this.axios.get(`comments/${this.id}`)
        this.comments = resp?.data?.data || []
    },
    props: [ 'id', 'commentsAmount' ]
}
</script>

<style scoped lang="scss">
    .comments {
        background-color: white;
        border-radius: 15px;
        padding: 3%;
        width: 100%;
        max-width: 790px;

        textarea {
            padding-right: 100px;
            border-radius: 12px;
        }
        .submit {
            margin-bottom: -30px;
            position: relative;
            top: -56px;
            right: 5px;
            display: flex;
            justify-content: flex-end;
            margin-left: calc(100% - 100px);

            button {
                border-radius: 10px;
                max-width: max-content;
            }
        }
        .comment {
            border-bottom: 2px solid rgba(128, 128, 128, 0.075);
        }
        .comment:last-child {
            border-bottom: none;
        }
    }
    @media (max-width: 767px) {
        .comments {
            border-radius: 0 !important;

            h5 {
                font-size: 18px !important;
            }
        }
    }
    @media (max-width: 540px) {
        textarea {
            padding-right: 10px !important;
        }
        .submit {
            margin-bottom: 0px !important;
            top: 0px !important;
        }
        .profile-pic {
            min-width: 30px !important;
            min-height: 30px !important;
            width: 30px !important;
            height: 30px !important;
        }
        .subcomments { 
            margin-left: -20px;

            .profile-pic {
                min-width: 24px !important;
                min-height: 24px !important;
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
</style>