<template>
    <div class="add">
        <a v-if="addData.type == 'other'" target="_blank" :href="addData.target_id">
            <img :src="addData.image" alt="">
        </a>
        <router-link :to="addData.type == 'news' ? `/news/${addData.target_id}` : `#`" v-else>
            <img :src="addData.image" alt="" >
        </router-link>
    </div>
</template>

<script>
export default {
    props: [ 'addData' ],
}
</script>

<style scoped>
    .add {
        transition: box-shadow 1s;
        overflow: hidden;
        /* background-color: white; */
        border-radius: 15px;
        margin-bottom: 15px;
        box-shadow: 0px 0px 0 transparent;
    }
    img {
        max-width: 100%;
        width: 100%;
    }
</style>