<template>
    <div class="right-sidebar">
        <template v-if="popular.length">
            <div class="populars-title">
                <h5 class="p-2 text-center mb-0">{{ t('popularCompanies') }}</h5>
            </div>
            <div class="popular">
                <div class="d-flex pop-company" v-for="popCompany in popular" :key="`popular ` + popCompany.company.id">
                    <div class="profile-pic mr-2" :style="popCompany.company.avatar ? {'background-image': `url(${popCompany.company.avatar})`} : {}" />
                    <div class="description">
                        <router-link :to="'/company/' + popCompany.company.id" class="d-flex router-link ">
                            <p class="text-dark">{{ popCompany.company.name }}
                                <b-icon-check-circle-fill class="verified ml-2" variant="primary" /> 
                            </p>
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
        <add v-for="add in addsList" :key="add.target_id" :addData="add" />
    </div>
</template>

<script>
import Add from './Add.vue';
export default {
    components: { Add },
    data() {
        return {
            addsList: [],
            popular: [],
        }
    },
    async mounted() {
        if(this.$store.state?.addsList?.length) {
            this.addsList = this.$store.state.addsList
        } else {
            const addsListResp = await this.axios('public/banners/all');
            const addsList = addsListResp.data?.data
            this.addsList = addsList
            this.$store.commit('setAddsList', addsList)
        }
        try {
            if(this.$store.state.bestCompaniesList?.length) {
                this.popular = this.$store.state.bestCompaniesList
            } else {
                const resp = await this.axios.get('company/famous')
                this.popular = resp?.data?.data || []
                this.$store.commit('setBestCompanies', this.popular)
            }
        } catch(ignored) { 0 }
    }
}
</script>

<style scoped>
.populars-title {
    margin-bottom: 0 !important;
    background-image: url("../assets/nearby-card.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center !important;
    padding: 10px 0;
    border-radius: 10px 10px 0 0;
    color: black !important;
    font-weight: 800 !important;
}
.popular {
    width: 100%;
    background-color: white;
    padding: 10px;
    border-radius: 0 0 15px 15px;
}
.pop-company {
    font-size: 16px !important; 
    align-items: center;
    margin-top: 5px;
    padding: 10px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.034);
}
.pop-company .profile-pic {
    width: 31px;
    min-width: 31px;
    height: 31px;
}
.pop-company p {
    margin-bottom: 0;
}

.pop-company:last-child {
    border-bottom: none;
}
</style>