export default {
  // global
  mainPageTitle: "Texnologiya, biznes va boshqalar haqida blog",
  mainPageDescription: "Techvibe — Oʻzbekistonda va butun dunyoda oʻz blogiga ega boʻlish, yangiliklar, texnologiyalar haqida maslahatlar yozishingiz mumkin boʻlgan veb-sayt",
  change: `O'zgartirish`,
  delete: `O'chirish`,
  write: `Yozish`,
  see: `Ko'rish`,
  copy: `Ko'chirish`,
  send: `Jo'natish`,
  logout: 'Chiqish',
  search: `Qidirish`,
  popular: 'Taniqli',
  share: `Ulashish`,
  others: 'Qoʻshimcha',
  hide: 'Berkitish',
  image: `rasm`,
  video: 'video',
  create: 'Yaratish',
  back: 'Qaytish',
  man: 'Erkak',
  woman: 'Ayol',
  crop: 'Kesib olish',
  theme: 'Mavzu',


  // timer
  today: 'bugun',
  yesterday: 'kecha',
  daysBefore: ' kun oldin',
  moreThanMonthBefore: 'bir oydan ko`proq oldin',
  moreThanYearBefore: 'bir yildan ko`proq oldin',

  // navbar
  login: 'Kirish',
  registrate: `Ro'yhatdan o'tish`,
  mainPage: 'Bosh sahifaga',
  blogs: 'Bloglar',
  itCompanies: 'IT-Kompaniyalar',
  events: 'Tadbirlar',
  notifications: 'Bildirishnomalar',
  uHaveNoNotifications: 'Siz hamma bildirishnomalarni ko`rib chiqibchiqgansiz',

  // sidebars
  newPost: 'Yangi maqola yaratish',
  address: `4A, Tepamasjid ko'chasi, M.Ulugbek tumani, Toshkent shaxri, 100170`,
  newEvent: `Yangi tadbir yaratish`,
  personalPage: `Personal sahifa`,
  exit: `Chiqish`,
  contacts: 'Kontaktlar',
  writeEmail: 'Pochtaga yozish',
  popularCompanies: 'Mashhur Bloglar',

  // news roll
  translations: 'Tarjimalar',
  itparkNews: 'IT Park yangiliklari',
  news: 'Maqolalar',
  noMorePosts: `Boshqa maqolalar yo'q`,
  postsNotFound: `Maqolalar topilmadi`,
  onlySubscribed: `Kuzatiladigan avtorlar yangiliklari`,
  settings: 'Sozlamalar',
  withinDay: `Bir kun ichida`,
  withinWeek: `Bir hafta ichida`,
  withinMonth: `Bir oy ichida`,
  withinYear: `Bir yil ichida`,
  fresh: `Yangi`,

  // comments
  reply: `Javob Yozish`,
  newComment: `Yangi komment yozish`,
  commentSee: `Komentariylarni ko'rish`,
  commentsCount: `Komentariylar soni`,
  noComments: `Komentariylar yo'q, birinchi bo'ling`,

  // company & user page
  follow: `Kuzatib borish`,
  followers: 'kuzatuvchilar:',
  unfollow: `Kuzatishni to'xtatish`,
  userPosts: 'Joylashtirilgan',
  moderating: 'Modaratsiyada',

  // company & user info change
  name: `Nom`,
  personalName: `Ism`,
  description: `Tavsif`,
  ava: `Avatar`,
  thumb: `Tepadagi rasm`,
  socials: `Ijtimoiy tarmoqlar`,
  password: `Parol`,
  chooseThumb: `Tepadagi rasmni tanlang`,
  changeCompName: `Kompaniya nomini o'zgartirish`,
  changeName: `Nomni o'zgartirish`,
  changeCompDescription: `Kompaniya tavsifini o'zgartirish`,
  changeDescription: `Tavsifni o'zgartirish`,
  changeProfilePhoto: `Profil yasmi (avatarni) o'zgartirish`,
  changeAva: `Avatarni o'zgartirish`,
  changeUpThumb: `Tepadagi rasmni o'zgartirish`,
  changeThumb: `Rasmni o'zgartirish`,
  socialLinks: `Ijtimoiy tarmoqlarga havolalar`,
  submitLinks: `Havolalarni o'zgartirish`,
  changePassword: `Parolni o'zgartirish`,
  changePersonalName: `Ismni o'zgartirish`,
  enterNewPassword: `Yangi parolni yozing`,
  repeatNewPassword: `Yangi parolni qaytaring`,
  changeInfo: `Informatsiyani o'zgartirish`,
  deleteAcc: 'Akkauntni o`chirish',

  // events registration
  signUp2Event: `Tadbirga yozilish`,
  AlreadySigned2Event: 'Siz bu tadbirga yozilgansiz',
  fullName: 'Ism Familiyangiz',
  phone: 'Telefon raqamingiz',
  age: 'Yoshingiz',
  sex: 'Jinsingiz',
  city: 'Shahar',

  //create post & event
  enterTitle: `Bu yerga temani yozing`,
  youtubeOrMover: `Youtube-dagi yo'ki mover-dagi videoga havola: `,
  videoUrl: `Videoga yo'nalishni kiriting`,
  enterDescription: `Bu yerga qisqa tavsif yozing`,
  choosePhoto: `Rasmni tanlang`,
  changePhoto: `Boshqa rasm`,
  newPostShort: `Yangi maqola`,
  tags: `Teglar`,
  publicate: `Joylashtirish`,
  newEventShort: `Yangi Tadbir`,
  beginingDate: `Boshlanish sanasi`,
  endDate: `Tugash sanasi`,
  futureEvents: `Bo'lajak tadbirlar`,
  currentEvents: `Hozir o'tyatgan`,
  finishedEvents: `Tugagan tadbirlar`,
  noMoreEvents: `Boshqa tadbirlar yo'q`,
  eventsNotFound: `Tadbirlar topilmadi`,

  // login && registrate
  yourEmail: `Elektron pochtangiz`,
  registration: `Ro'yhatdan o'tish`,
  yourPasword: `Parolingiz`,
  loginGoogle: `Google orqali kirish`,
  rememberMe: `Meni eslab qolish`,
  dontRememberPassword: `Parol esdan chiqtimi?`,
  noProfile: `Profiligiz yo'q bo'lsa, unda`,
  readyToLogout: `Akkauntingizdan chiqib ketishga tayormisiz?`,
  noGetBack: `Yoq, qaytish`,
  yesLogout: `Ha, chiqish`,
  yourName: 'Sizning Ismingiz',
  doAcceptPolicy: 'Foydalanuvchi shartnomasining shartlarini qabul qilaman',
  haveProfile: `Profiligiz bo'lsa, unda`,

  // notifications
  "news deleted": `Maqola o'chirib tashlandi`,
  "news publish": `Maqola Joylashtirildi`,

  // errors
  suchCompanyExists: 'Bunday ismli kompaniya bor',
  fillEmailField: `Elektron pochta bilan parol boshlig'larini to'ldiring`,
  wrongEmail: `Elektron pochta toto'g'ri terilgan`,
  emailOrPasswordFilledWrong: 'Elektron pochta yo`ki parol no`tug`ri terilgan',
  wrongSecondPassword: "Parol ikkinchi marta no'tug'ri terilgan",
  enterName: "Isimingizni yozishingiz kerak",
  enterEmail: "Elektron pochtangizni yozing",
  acceptPolicy: "Foydalanuvchi shartlarini qabul qilish kerak",
  suchEmailExists: "Bunday elektron pochtali foydalanuvchi mavjud, agar pochta sizniki bo'lsa unda kiring",
  imageTooBig: 'Rasm fayli juda katta',
  imageNeeded: `Maqolada Asosiy rasm bo'lishi shart`,
  titleNeeded: 'Maqola temasini yozish shart',
  fillWithText: `Maqolani tekst bilan to'ldirin`,
  tagsNeeded: `Maqolada teglar yozish shart`,
  descriptionNeeded: `Qisqa tavsif qo'shing`,
  addThumbnail: `Maqolada Asosiy rasm bo'lishi shart`,
  failed2openPost: `Maqolani ochish bilan xato paydo bo'ldi`,
  postNotFound: `Maqola topilmadi`,
  videoWrong: `Videoga havola noto'g'ri ko'rsatilgan`,
  errorWithEvent: 'Ro`yhatdan o`tish bilan xato paydo bo`ldi',
  phoneWrong: 'Telefon noto`g`ri terilgan',
  wrongPassword: `Parol noto'g'ri terilgan`,
  verifyNotRobot: `Siz robot emasligingizni tasdiqlang`,

  //warning
  commentCantBeEmpty: 'Komentariy bo`sh bo`la olmaydi',
  commentHasNoWords: 'Komentariyda kamida bir so`z bo`lishi shart',
  login2SeeAuthor: `Avtorni ko'rib chiqish uchun kirish amalga oshiring`,
  login2See: `Ko'rish uchun kirish amalga oshiring`,
  login2Like: `Layk qo'yish uchun kirish amalga oshiring`,
  enterToWriteComment: 'Komentariy yozish uchun kirish amalga oshiring',
  passwordRetypedWrong: 'Parol ikkinchi notug`ri qaytarilgan',
  passwordTooShort: 'Parol 8 simvoldan uzunroq bo`lishi shart',
  you: 'Siz ',
  stoppedFollowing: `-ni kuzatishni to'xtattingiz`,
  startedFollowing: `-ni kuzatishni boshladingiz`,
  postDeleted: 'Sizning maqolangiz omadli olib tashlandi',

  // alert
  NameHasChanged: 'Nom o`zgartirildi',
  personalNameHasChanged: 'Ism o`zgartirildi',
  descriptionHasChanged: 'Tavsif o`zgartirildi',
  avaHasChanged: 'Avatar o`zgartirildi',
  thumbnailChanged: 'Tepadagi rasm o`zgartirildi',
  socialsHaveChanged: `Ijtimoiy tarmoqlar o'zgartirildi`,
  passwordHasChanged: 'Parol o`zgartirildi',
  newPasswordHasSent: "Yangi parol sizning elektron pochtangizga jo`natildi",
  eventPremoderating: 'Tadbir moderatsiyaga jonatildi',
  postPremoderating: 'Maqola moderatsiyaga jonatildi',
  copied2clipboard: 'Ulashish havolasi ko`chirib olindi',
  sure2DeletePost: 'Bu maqolani o`chirib tashlashga tayormisiz?',
  youRegisteredEvent: `Siz shu tadbirga yozildingiz`,


  policy: `<h1>FOYDALANUVCHI KELISHUV BITIMI</h1>
  <p>Mazkur bitim ommaviy oferta hisoblanadi va Saytda Internet tarmog‘ida joylashtirilgan materiallar va xizmatlardan quyidagi manzilda foydalanish shartlarini belgilaydi:www.techvibe.uz, ushbu Internet-saytning mehmonlari va Foydalanuvchilari (bundan buyon — Sayt).</p>
  <h3>1. Umumiy holat</h3>
  <p>1.1. Sayt materiallari va servislaridan foydalanish O‘zbekiston Respublikasining amaldagi qonunchiligi normalari bilan tartibga solinadi.</p>
  <p>1.2. Foydalanuvchi Sayt materiallaridan va Saytda taqdim etiladigan xizmatlardan istalgan usulda, O‘zbekiston Respublikasi amaldagi qonunchiligining ruxsat etilgan normalariga muvofiq foydalanish huquqiga ega.</p>
  <p>1.3. Sayt materiallariga kirish va Sayt servislaridan foydalanish Foydalanuvchining ushbu Bitim va unda ko‘rsatilgan shaxsiy ma’lumotlarni qayta ishlash shartlari bilan so‘zsiz roziligini bildiradi; ushbu shartlarga rozi bo‘lmagan taqdirda, Foydalanuvchi Sayt xizmatlaridan foydalanishdan bosh tortishi kerak.</p>
  <h3>2. Foydalanuvchi Majburiyatlari</h3>
  <p>2.1. Foydalanuvchi o‘zbek qonunlari yoki xalqaro huquqni, shu jumladan intellektual mulk, mualliflik huquqi va/yoki turdosh huquqlar sohasida, umume’tirof etilgan axloq va axloq me’yorlarini buzgan deb hisoblanishi mumkin bo‘lgan harakatlar qilmaslikka, izohlar va yozuvlarni qoldirmaslikka, shuningdek, Saytning normal ishlashi va Sayt xizmatlarining buzilishiga olib keladigan yoki olib kelishi mumkin bo‘lgan har qanday harakatlar qilmaslikka rozi.</p>
  <p>2.2. Huquq egalarining roziligisiz Sayt materiallaridan foydalanishga yo‘l qo‘yilmaydi.</p>
  <p>2.3. Sayt materiallarini, shu jumladan himoyalangan mualliflik asarlarini iqtibos keltirganda Saytga havola qoldirish majburiydir.</p>
  <p>2.4. Sayt Ma’muriyati Foydalanuvchining tashrifi va Saytda bo‘lishi mumkin bo‘lgan tashqi manbalar, havolalar uchun javobgar emas.</p>
  <p>2.5. Sayt Ma’muriyati Saytning har qanday kontenti bilan bog‘liq har qanday yo‘qotish yoki zararlar, mualliflik huquqini ro‘yxatdan o‘tkazish va bunday ro‘yxatga olish to‘g‘risidagi ma’lumotlar, mavjud bo‘lgan tovarlar yoki xizmatlar yoki tashqi saytlar, havolalar orqali kirgan resurslar, olingan xizmatlar yoki Saytda joylashtirilgan axborot yoki tashqi resurslar yoki Foydalanuvchining boshqa kontaktlari uchun Foydalanuvchi oldida bevosita yoki bilvosita javobgar ega emas.</p>
  <p>2.6. Foydalanuvchi Sayt Ma’muriyati hech qanday javobgarlikni o‘z zimmasiga olmaydi va Saytda e’lon qilinishi mumkin bo‘lgan reklama tufayli hech qanday majburiyatlarga ega emasligiga rozi.</p>
  <h3>3. Maxfiylik siyosati</h3>
  <p>3.1. Ushbu Bitim doirasida Foydalanuvchining shaxsiy ma’lumotlari quyidagilarni anglatadi:</p>
  <p>3.1.1.Foydalanuvchi ro‘yxatga olish (hisob qaydnomasini yaratish) yoki xizmatlardan foydalanish jarayonida, shu jumladan Foydalanuvchining shaxsiy ma’lumotlarini mustaqil ravishda taqdim etadigan shaxsiy ma’lumot. Xizmatlarni taqdim etish uchun majburiy bo‘lgan ma’lumotlar maxsus tarzda belgilangan. Boshqa ma’lumotlar foydalanuvchi tomonidan o‘z ixtiyori bilan taqdim etiladi.
  </p>
  <p>3.1.2. Foydalanuvchi qurilmasida o‘rnatilgan dasturiy ta’minot yordamida, shu jumladan IP-manzil, cookie-fayllar, Foydalanuvchining brauzeri haqidagi ma’lumot (yoki xizmatlarga kiriladigan boshqa dastur) yordamida Sayt xizmatlariga avtomatik tarzda uzatiladigan ma’lumotlar, Foydalanuvchi foydalanadigan uskuna va dasturiy ta’minotning texnik tavsiflari, xizmatlarga kirish sanasi va vaqti, so‘ralgan sahifalar manzillari va boshqa shunga o‘xshash ma’lumotlar.</p>
  <p>3.1.2. Foydalanuvchi qurilmasida o‘rnatilgan dasturiy ta’minot yordamida, jumladan, IP-manzil, cookie-fayllar ma’lumotlari, Foydalanuvchi Brauzeri (yoki xizmatlarga kirish imkoni bo‘lgan boshqa dastur), Foydalanuvchi tomonidan foydalaniladigan uskunalar va dasturiy ta’minotning texnik xususiyatlari, xizmatlarga kirish sanasi va vaqti, so‘ralgan sahifalar manzili va boshqa shunga o‘xshash ma’lumotlar yordamida Sayt xizmatlariga avtomatik ravishda uzatiladigan ma’lumotlar.
  </p>
  <p>3.2. Sayt faqat Sayt servislarini taqdim etish yoki ushbu bitimni bajarish uchun zarur bo‘lgan shaxsiy ma’lumotlarni to‘playdi va saqlaydi, qonun hujjatlarida belgilangan muddat davomida shaxsiy axborotni majburiy saqlash nazarda tutilgan hollar bundan mustasno.</p>
  <p>3.3. Foydalanuvchining shaxsiy ma’lumotlarini Sayt quyidagi maqsadlar uchun ishlov beradi:</p>
  <p>3.3.1 Saytda ko‘rsatilgan xizmatlarni taqdim etish bo‘yicha shartnoma tuzish uchun Saytda ro‘yxatdan o‘tgan Foydalanuvchini identifikatsiya qilish.</p>
  <p>3.3.2. Foydalanuvchi bilan qayta aloqa o‘rnatish, shu jumladan bildirishnomalar, Saytdan foydalanish, xizmatlar ko‘rsatish, so‘rov va arizalarni Foydalanuvchi tomonidan qayta ishlash bilan bog‘liq so‘rovlar.</p>
  <p>3.3.3. Xavfsizlikni ta’minlash, firibgarlikning oldini olish uchun Foydalanuvchining manzilini aniqlash.</p>
  <p>3.3.4. Foydalanuvchining hisob qaydnomasini yaratish.</p>
  <p>3.3.5. Saytdan foydalanish bilan bog‘liq muammolar yuzaga kelganda Foydalanuvchiga samarali mijoz va texnik yordam ko‘rsatish.</p>
  <p>3.3.6. Reklama faoliyatini amalga oshirish.</p>
  <p>3.4. Sayt Foydalanuvchilarning shaxsiy ma’lumotlarini muayyan xizmatlarning ichki qoidalariga muvofiq saqlaydi.</p>
  <p>3.5. Foydalanuvchining shaxsiy ma’lumotlarining maxfiyligi saqlanib qolinadidi, Foydalanuvchi tomonidan cheklanmagan doiradagi shaxslarga umumiy foydalanish uchun o‘zi to‘g‘risidagi axborotni ixtiyoriy ravishda taqdim etgan hollar bundan mustasno. Boshqa xizmatlardan foydalanishda Foydalanuvchi o‘zining shaxsiy ma’lumotlarining ma’lum bir qismi ommaga ochiq bo‘lishiga rozilik beradi.</p>
  <p>3.6. Foydalanuvchining shaxsiy ma’lumotlarini qayta ishlash har qanday qonuniy yo‘l bilan, shu jumladan, avtomatlashtirish vositalaridan foydalangan holda yoki bunday vositalardan foydalanmasdan shaxsiy ma'lumotlarning axborot tizimlarida muddatsiz amalga oshiriladi.</p>
  <p>3.7. Sayt Ma’muriyati Foydalanuvchining shaxsiy ma’lumotlarini noqonuniy yoki tasodifiy kirishdan, yo‘q qilishdan, o‘zgartirishdan, bloklashdan, nusxalashdan, tarqatishdan, shuningdek uchinchi shaxslarning boshqa noqonuniy harakatlaridan himoya qilish uchun zarur tashkiliy va texnik choralarni ko‘radi.</p>
  <p>3.8. Foydalanuvchi Sayt Ma’muriyatiga Foydalanuvchi bilan hamkorlik qilish, Foydalanuvchiga ko‘rsatilgan xizmatlar, Sayt Ma’muriyati portfoliosida (shu jumladan reklama maqsadlarida) ko‘rsatilishi uchun bunday xizmatlar va Foydalanuvchi ismi (nomi) natijalarining fragmentlaridan foydalanish huquqini beradi.</p>
  <h3>4. Boshqa shartlar</h4>
  <p>4.1. Ushbu kelishuvdan kelib chiqadigan yoki u bilan bog‘liq barcha mumkin bo‘lgan nizolar O‘zbekiston Respublikasining amaldagi qonunchiligiga muvofiq hal etiladi.</p>
  <p>4.2. Ushbu shartnomaning biron-bir qoidasi yoki shartlari haqiqiy emas deb topilgan, yuridik kuchdan mahrum bo‘lgan yoki tegishli yurisdiktsiya sud tomonidan bekor qilingan taqdirda, ushbu shartnomaning qolgan qoidalari o‘z kuchini saqlab qoladi va o‘zgarishsiz davom etadi, amaldagi qonun tufayli ahamiyatsiz deb topilgan qoidalar o‘rniga haqiqiy, ya’ni mohiyat va ahamiyatga ega bo‘lgan avvalgisiga nisbatan iloji boricha yaqinrog‘iga almashtiriladi.</p>
  <p>4.3 Sayt Ma’muriyati tomonidan Foydalanuvchilarning kelishuv qoidalarini buzgan taqdirda harakatsizligi Sayt Ma’muriyatidan o‘z manfaatlarini himoya qilish va mualliflik huquqlarini himoya qilish uchun tegishli choralarni ko‘rish huquqidan mahrum qilmaydi.</p>
  <p>4.4. Sayt Ma’muriyati istalgan vaqtda ushbu Bitim hartlarini bir tomonlama o‘zgartirish huquqiga ega. Bunday o‘zgarishlar Saytdagi bitimning yangi versiyasini joylashtirish kunidan keyin kuchga kiradi. Agar Foydalanuvchi kiritilgan o‘zgarishlarga rozi bo‘lmasa, u Saytga kirishni rad etishi, Sayt materiallari va xizmatlaridan foydalanishni to‘xtatishi shart.</p>
  `
}